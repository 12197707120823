import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { makeStyles, Grid } from '@material-ui/core';
import {
	DialogForm,
	GridContainer,
	LinkedSingleSelect,
} from '@sporkbytes/material-ui-kit-react';
import { mergeDuplicates } from '@sporkbytes/api-client/PartnerMenuItems';
import { getDiff } from '@sporkbytes/object-utils';
import { omit } from 'lodash-es';

import PartnerMenuItemDetails from 'components/partner-menu-items/PartnerMenuItemDetails';
import PartnerMenuItemFormElements, {
	validationSchema,
} from 'components/partner-menu-items/PartnerMenuItemFormElements';

import * as yup from 'models/validation';
import axios from 'services/data';

const useStyles = makeStyles(theme => ({
	itemToMerge: {
		flex: 1,
		minWidth: 225,
	},
	partnerMenuItemFormElements: {
		flex: 1,
		maxWidth: theme.sizes.contentMaxWidth,
		[theme.breakpoints.up('sm')]: {
			minWidth: 600,
		},
	},
}));

const PARTNER_MENU_ITEM_QUERY = gql`
	query PartnerMenuItemMergeDuplicates($ids: [uuid!]!, $PartnerId: uuid!) {
		PartnerMenuItems(where: { id: { _in: $ids } }) {
			id
			cost
			description
			feedsMaximum
			feedsMinimum
			name
			howToOrder
			printableName
			producesLabel
			showInDriverChecklist
			size
			visibleInMenu
			PartnerMenuSection {
				id
				name
			}
			PartnerMenuItemsDietaryTags(
				order_by: { DietaryTag: { sortOrder: asc } }
			) {
				DietaryTag {
					id
				}
			}
			...PartnerMenuItemDetails
		}
		PartnerMenuSections(
			where: { PartnerId: { _eq: $PartnerId } }
			order_by: { sortOrder: asc }
		) {
			id
			name
		}
	}
	${PartnerMenuItemDetails.fragments.details}
`;

const PartnerMenuItemMergeDuplicatesDialog = ({
	afterSubmit,
	itemsToMergeIds,
	PartnerId,
	...props
}) => {
	const classes = useStyles();
	const { data, loading } = useQuery(PARTNER_MENU_ITEM_QUERY, {
		variables: { ids: itemsToMergeIds, PartnerId },
	});

	let initialValues = {};
	const itemsToMerge = data?.PartnerMenuItems || [];

	if (!loading) {
		const difference = itemsToMerge.reduce(
			(difference, item) => ({
				...difference,
				...getDiff(item, itemsToMerge[0]),
			}),
			{}
		);

		const fieldsToKeep = omit(itemsToMerge[0], Object.keys(difference));

		[
			'active',
			'producesLabel',
			'showInDriverChecklist',
			'visibleInMenu',
		].forEach(key => {
			if (difference[key] !== undefined) {
				initialValues[key] = true;
			}
		});

		initialValues.PartnerMenuSectionId =
			fieldsToKeep.PartnerMenuSection?.id || null;

		initialValues.DietaryTags =
			fieldsToKeep.PartnerMenuItemsDietaryTags?.map(
				({ DietaryTag: { id } }) => id
			) || [];

		initialValues = { ...initialValues, ...fieldsToKeep };
	}

	return (
		<DialogForm
			{...props}
			loading={loading}
			validationSchema={validationSchema.concat(
				yup.object().shape({
					PartnerMenuSectionId: yup.string().nullable(),
				})
			)}
			initialValues={initialValues}
			onSubmit={values =>
				mergeDuplicates(axios, {
					newItemData: values,
					oldItemIds: itemsToMergeIds,
				}).then(afterSubmit)
			}
			DialogProps={{ fullScreen: true }}
			enableReinitialize
		>
			{() => (
				<Grid container justify="center" spacing={4}>
					{itemsToMerge.map(item => (
						<Grid
							key={item.id}
							item
							className={classes.itemToMerge}
						>
							<PartnerMenuItemDetails partnerMenuItem={item} />
						</Grid>
					))}
					<Grid item className={classes.partnerMenuItemFormElements}>
						<GridContainer columns={2}>
							<LinkedSingleSelect
								name="PartnerMenuSectionId"
								label="Partner Menu Section"
								emptyOptionLabel="None"
								options={data?.PartnerMenuSections}
								getOptionValue={option => option.id}
								getOptionLabel={option => option.name}
								getOptionKey={option => option.id}
							/>
						</GridContainer>
						<PartnerMenuItemFormElements />
					</Grid>
				</Grid>
			)}
		</DialogForm>
	);
};

PartnerMenuItemMergeDuplicatesDialog.propTypes = {
	afterSubmit: PropTypes.func.isRequired,
	itemsToMergeIds: PropTypes.arrayOf(PropTypes.string).isRequired,
	PartnerId: PropTypes.string.isRequired,
};

export default PartnerMenuItemMergeDuplicatesDialog;
