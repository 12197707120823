import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import {
	approve,
	deliver,
	disapprove,
	put,
	requestClientApproval,
	requestClientConfirmation,
} from '@sporkbytes/api-client/MealProposals';
import {
	requestApproval as requestPartnerApproval,
	requestConfirmation as requestPartnerConfirmation,
} from '@sporkbytes/api-client/PurchaseOrders';
import { canEdit } from '@sporkbytes/meal-proposal-utils';
import { pick } from 'lodash-es';

import {
	copyPurchaseOrderLink,
	editPurchaseOrder,
	machineEventActionObjects,
	requestPartnerResponse,
	sendPartnerCancellationNotice,
} from 'components/meal-proposals/MealProposalActionMenu/actions/';
import ActionMenu from 'components/utilities/ActionMenu';
import MealProposalAssignMealCategoriesDialog from 'components/meal-proposals/MealProposalAssignMealCategoriesDialog';
import MealProposalAssignDeliveryDriversDialog from 'components/meal-proposals/MealProposalAssignDeliveryDriversDialog';
import MealProposalAssignDeliveryGearDialog from 'components/meal-proposals/MealProposalAssignDeliveryGearDialog';
import MealProposalCorrectionDialogForm from 'components/meal-proposals/MealProposalCorrectionDialogForm';
import MealProposalReassignSporkContactDialog from 'components/meal-proposals/MealProposalReassignSporkContactDialog';
import MealProposalRequestClientResponseDialog from 'components/meal-proposals/MealProposalRequestClientResponseDialog';
import MealProposalSendClientCancellationNoticeDialog from 'components/meal-proposals/MealProposalSendClientCancellationNoticeDialog';

import { useStore } from 'models/store';

import { createStateEventActions } from 'services/actionMenu';
import { copyText } from 'services/utilities';
import { useAuth } from 'services/auth';
import axios from 'services/data';

import useMealProposalMachine from 'hooks/useMealProposalMachine';
import useQueryString from 'hooks/useQueryString';

import mealProposalMachine from 'machines/mealProposalMachine';

const MealProposalActionMenu = ({ id, SporkLocationId }) => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [queryString] = useQueryString();
	const { role, userCan } = useAuth();
	const { showDialog, showSuccessNotification } = useStore();
	const guards = {
		canEdit: context => canEdit(context, role),
	};
	const [state, send] = useMealProposalMachine(id, {
		actions: {
			approve: () => approve(axios, id).then(showSuccessNotification),
			assignDeliveryDrivers: () =>
				showDialog(MealProposalAssignDeliveryDriversDialog, {
					id,
					SporkLocationId,
				}),
			assignDeliveryGear: () =>
				showDialog(MealProposalAssignDeliveryGearDialog, {
					id,
					SporkLocationId,
				}),
			assignMealCategories: () =>
				showDialog(MealProposalAssignMealCategoriesDialog, { id }),
			cancel: () =>
				history.pushNamedRoute('mealProposalsCancel', {
					id,
				}),
			copyClientLink: () =>
				copyText(
					history.getNamedRouteUrl('publicMealProposalsDetail', {
						id,
					})
				),
			copyPurchaseOrderLink: copyPurchaseOrderLink({
				history,
				id,
				setLoading,
			}),
			deliver: () => deliver(axios, id).then(showSuccessNotification),
			duplicate: () =>
				history.pushNamedRoute('mealProposalsDuplicate', { id }),
			disapprove: () =>
				disapprove(axios, id).then(showSuccessNotification),
			edit: () =>
				history.pushNamedRoute('mealProposalsEdit', {
					id,
				}),
			editPurchaseOrder: editPurchaseOrder({
				history,
				id,
				setLoading,
			}),
			manageMealOptions: () =>
				history.pushNamedRoute('mealProposalsMealOptionsManage', {
					id,
					...pick(queryString, ['meal-option']),
				}),
			openHubSpotDeal: () =>
				window.open(
					`https://app.hubspot.com/contacts/${process.env.REACT_APP_HUB_SPOT_ACCOUNT_ID}/record/0-3/${state.context?.HubSpotDealId}/`
				),
			printLabels: () =>
				history.pushNamedRoute('mealProposalsPrintableLabels', {
					id,
				}),
			reassignSporkContact: () =>
				showDialog(MealProposalReassignSporkContactDialog, {
					id,
				}),
			requestClientApproval: () =>
				showDialog(MealProposalRequestClientResponseDialog, {
					emailTemplateId: 'requestClientApproval',
					id,
					sendEmail: requestClientApproval,
				}),
			requestClientConfirmation: () =>
				showDialog(MealProposalRequestClientResponseDialog, {
					emailTemplateId: 'requestClientConfirmation',
					id,
					sendEmail: requestClientConfirmation,
				}),
			requestPartnerApproval: () =>
				requestPartnerResponse({
					emailTemplateId: 'requestPartnerApproval',
					sendEmail: requestPartnerApproval,
					variables: {
						id,
						purchaseOrderApproved: false,
						purchaseOrderConfirmed: false,
					},
				}),
			requestPartnerConfirmation: () =>
				requestPartnerResponse({
					emailTemplateId: 'requestPartnerConfirmation',
					sendEmail: requestPartnerConfirmation,
					variables: {
						id,
						purchaseOrderApproved: true,
						purchaseOrderConfirmed: false,
					},
				}),
			sendClientCancellationNotice: () =>
				showDialog(MealProposalSendClientCancellationNoticeDialog, {
					id,
				}),
			sendPartnerCancellationNotice: () =>
				sendPartnerCancellationNotice({
					id,
				}),
			sendCorrection: () =>
				showDialog(MealProposalCorrectionDialogForm, { id }),
			viewPhoto: () => window.open(state.context?.deliveryPhotoUrl),
		},
		guards,
		services: {
			convertToDraft: () =>
				put(axios, id, {
					status: 'Draft',
				}),
			submit: () =>
				put(axios, id, {
					status: 'In Progress',
				}),
		},
	});

	return (
		<ActionMenu
			loading={loading}
			options={createStateEventActions(
				state,
				mealProposalMachine(guards),
				send,
				machineEventActionObjects({ state, userCan }),
				{ hiddenEvents: ['UPDATE_DATA'], showDivider: false }
			)}
		/>
	);
};

MealProposalActionMenu.propTypes = {
	id: PropTypes.string.isRequired,
	SporkLocationId: PropTypes.string.isRequired,
};

MealProposalActionMenu.fragments = {
	details: gql`
		fragment MealProposalActionMenuDetails on MealProposals {
			id
			SporkLocationId
		}
	`,
};

export default MealProposalActionMenu;
