import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { VerifiedUser } from '@material-ui/icons';
import { getAutocomplete, getVerify } from '@sporkbytes/api-client/Addresses';
import { AsyncAutocomplete } from '@sporkbytes/material-ui-kit-react';
import { omit } from 'lodash-es';

import axios from 'services/data';

const useStyles = makeStyles(theme => ({
	verifiedIcon: {
		color: theme.palette.success[800],
	},
}));

const AddressSearch = ({ disabled, onChange }) => {
	const classes = useStyles();

	return (
		<AsyncAutocomplete
			disabled={disabled}
			placeholder="Search for an address"
			getSearchResults={searchTerm =>
				getAutocomplete(axios, { q: searchTerm })
			}
			renderOption={renderOption({ classes })}
			getOptionLabel={option => option.friendlyName}
			onChange={onAddressSelect({ onChange })}
		/>
	);
};

AddressSearch.propTypes = {
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

export default AddressSearch;

function onAddressSelect({ onChange }) {
	return address => {
		if (address) {
			if (address.verified) {
				onChange({
					...omit(address, ['friendlyName', 'fullAddress']),
					streetAddress2: address.streetAddress2 || undefined,
				});
			} else {
				getVerify(axios, {
					q: address.fullAddress,
				})
					.then(({ streetAddress2, ...suggestedAddress }) =>
						onChange({
							...suggestedAddress,
							streetAddress2: streetAddress2 || undefined,
						})
					)
					.catch(() =>
						onChange({
							streetAddress: undefined,
							streetAddress2: undefined,
							city: undefined,
							state: undefined,
							zipCode: undefined,
						})
					);
			}
		}
	};
}

function renderOption({ classes }) {
	return option => {
		const friendlyNameAndStreetAddressAreEqual =
			option.friendlyName === option.streetAddress;
		const fullAddressContainsFriendlyName = option.fullAddress.includes(
			option.friendlyName
		);
		const showFriendlyName =
			!friendlyNameAndStreetAddressAreEqual &&
			!fullAddressContainsFriendlyName;

		return (
			<Grid container spacing={2} alignItems="center">
				{option.verified ? (
					<Grid item>
						<VerifiedUser className={classes.verifiedIcon} />
					</Grid>
				) : null}
				<Grid item xs>
					{showFriendlyName ? (
						<Typography>{option.friendlyName}</Typography>
					) : null}
					<Typography>{option.fullAddress}</Typography>
				</Grid>
			</Grid>
		);
	};
}
