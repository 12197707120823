import React from 'react';
import { Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { pick } from 'lodash-es';

const useStyles = makeStyles(theme => ({
	paper: {
		height: '100%',
		padding: theme.spacing(2),
	},
}));

const WidgetContainer = ({ style, ...props }) => {
	const classes = useStyles();

	const sizes = pick(props, ['xs', 'sm', 'md', 'lg', 'xl']);

	return (
		<Grid item {...sizes}>
			<Card className={classes.paper} style={style}>
				{props.children}
			</Card>
		</Grid>
	);
};

export default WidgetContainer;
