import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { CardContent } from '@material-ui/core';
import {
	DateRangeSelector,
	GridContainer,
} from '@sporkbytes/material-ui-kit-react';
import { getAllUpcoming } from '@sporkbytes/time-utils';
import { isApproved, isConfirmed } from '@sporkbytes/object-utils';
import { pick } from 'lodash-es';

import ApprovedConfirmedStatus from 'components/content/ApprovedConfirmedStatus';
import DateOutput from 'components/content/DateOutput';
import DetailPageLink from 'components/routing/DetailPageLink';
import RemoteTable from 'components/content/RemoteTable';

const getPurchaseOrdersQuery = () => gql`
	query ContactMeals(
		$ContactId: uuid!
		$beginDate: timestamptz
		$endDate: timestamptz
		$limit: Int!
		$offset: Int!
		$orderNumberTextOrderBy: order_by
		$partnerNameOrderBy: order_by
		$pickupTimeOrderBy: order_by
		$headCountOrderBy: order_by
	) {
		PurchaseOrders(
			order_by: {
				MealOption: {
					MealProposal: {
						orderNumberText: $orderNumberTextOrderBy
						headCount: $headCountOrderBy
					}
				}
				PartnerLocation: { Partner: { name: $partnerNameOrderBy } }
				pickupTime: $pickupTimeOrderBy
			}
			limit: $limit
			offset: $offset
			where: {
				MealOption: {
					approved: { _eq: true }
					MealProposal: { status: { _nin: ["Canceled", "Draft"] } }
				}
				pickupTime: { _gte: $beginDate, _lte: $endDate }
				PurchaseOrdersContacts: { ContactId: { _eq: $ContactId } }
			}
		) {
			pickupTime
			id
			approved
			confirmed
			PartnerLocation {
				id
				friendlyName
				timezone
				Partner {
					id
					name
				}
			}
			MealOption {
				MealProposal {
					id
					orderNumberText
					headCount
				}
			}
		}
		PurchaseOrders_aggregate(
			where: {
				MealOption: {
					approved: { _eq: true }
					MealProposal: { status: { _nin: ["Canceled", "Draft"] } }
				}
				pickupTime: { _gte: $beginDate, _lte: $endDate }
				PurchaseOrdersContacts: { ContactId: { _eq: $ContactId } }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const PurchaseOrdersTable = ({ ContactId }) => {
	const [dateRange, setDateRange] = useState(getAllUpcoming());
	const ref = useRef();

	return (
		<>
			<CardContent>
				<DateRangeSelector
					defaultDateRange={dateRange}
					onChange={setDateRange}
				/>
			</CardContent>
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'Order #',
						field: 'orderNumberText',
						render: ({
							MealOption: {
								MealProposal: { id, orderNumberText },
							},
						}) => (
							<DetailPageLink
								entityType="MealProposals"
								id={id}
								text={orderNumberText}
							/>
						),
					},
					{
						title: 'Partner',
						field: 'partnerName',
						render: ({
							PartnerLocation: { Partner, ...partnerLocation },
							...purchaseOrder
						}) => (
							<GridContainer alignItems="center">
								<ApprovedConfirmedStatus
									approved={isApproved(purchaseOrder)}
									confirmed={isConfirmed(purchaseOrder)}
									showText={false}
								/>
								<>
									<p>
										<DetailPageLink
											entityType="Partners"
											id={Partner.id}
											text={Partner.name}
										/>
									</p>
									<p>
										<DetailPageLink
											entityType="PartnerLocations"
											id={partnerLocation.id}
											text={partnerLocation.friendlyName}
										/>
									</p>
								</>
							</GridContainer>
						),
					},
					{
						title: 'Pickup Time',
						field: 'pickupTime',
						render: ({
							PartnerLocation: { timezone },
							pickupTime,
						}) => (
							<DateOutput
								date={pickupTime}
								timezone={timezone}
								formatter="dateAndTimeMedium"
							/>
						),
					},
					{
						title: 'Head Count',
						field: 'headCount',
						type: 'numeric', // This will left align the head count column
						render: ({
							MealOption: {
								MealProposal: { headCount },
							},
						}) => headCount,
					},
				]}
				defaultOrderBy="pickupTime"
				defaultOrderDirection="asc"
				getQuery={getPurchaseOrdersQuery}
				options={{
					search: false,
				}}
				queryEntity="PurchaseOrders"
				variables={{
					ContactId,
					...pick(dateRange, ['beginDate', 'endDate']),
				}}
			/>
		</>
	);
};

PurchaseOrdersTable.propTypes = {
	ContactId: PropTypes.string.isRequired,
};

export default PurchaseOrdersTable;
