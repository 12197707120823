import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { startCase } from 'lodash-es';
import { GridContainer } from '@sporkbytes/material-ui-kit-react';
import { getClientApproved } from '@sporkbytes/meal-option-utils';
import {
	clientHasApproved,
	clientHasConfirmed,
} from '@sporkbytes/meal-proposal-utils';

import ApprovedConfirmedStatus from 'components/content/ApprovedConfirmedStatus';
import DateOutput from 'components/content/DateOutput';
import DetailPageLink from 'components/routing/DetailPageLink';
import MealProposalActionMenu from 'components/meal-proposals/MealProposalActionMenu';
import MealProposalStatus from 'components/meal-proposals/MealProposalStatus';

const getColumns = ({ filteringBy, orderBy, orderDirection }) =>
	[
		{
			title: 'Order #',
			field: 'orderNumber',
			render: ({ id, name, orderNumberText, status }) => (
				<GridContainer alignItems="center">
					<MealProposalStatus status={status} />
					<DetailPageLink
						entityType="MealProposals"
						id={id}
						text={`${orderNumberText}${name ? `: ${name}` : ''}`}
					/>
				</GridContainer>
			),
		},
		{
			title: 'Client',
			field: 'clientName',
			render: ({
				ClientLocation: { Client, ...clientLocation },
				...mealProposal
			}) => (
				<GridContainer alignItems="center">
					<ApprovedConfirmedStatus
						approved={clientHasApproved(mealProposal)}
						confirmed={clientHasConfirmed(mealProposal)}
						showText={false}
					/>
					<>
						<p>
							<DetailPageLink
								entityType="Clients"
								id={Client.id}
								text={Client.name}
							/>
						</p>
						<p>
							<DetailPageLink
								entityType="ClientLocations"
								id={clientLocation.id}
								text={clientLocation.friendlyName}
							/>
						</p>
					</>
				</GridContainer>
			),
		},
		{
			title: startCase(filteringBy),
			field: filteringBy,
			render: ({ ClientLocation: { timezone }, ...mealProposal }) => (
				<DateOutput
					date={mealProposal[filteringBy]}
					timezone={timezone}
					formatter="dateAndTimeMedium"
				/>
			),
		},
		{
			title: 'Head Count',
			field: 'headCount',
			type: 'numeric', // This will left align the head count column
		},
		{
			title: 'Pickups',
			field: 'partner',
			sorting: false,
			render: ({ MealOptions }) => {
				const approvedMealOption = getClientApproved(MealOptions);

				return (
					approvedMealOption?.PurchaseOrders?.map(purchaseOrder => {
						const {
							approved,
							confirmed,
							id,
							PartnerLocation: {
								Partner: { id: PartnerId, name },
								friendlyName,
								timezone,
							},
							pickupTime,
						} = purchaseOrder;

						return (
							<GridContainer alignItems="center" key={id}>
								<ApprovedConfirmedStatus
									approved={approved}
									confirmed={confirmed}
									showText={false}
								/>
								<Tooltip title={friendlyName}>
									<p>
										<DetailPageLink
											entityType="Partners"
											id={PartnerId}
											text={name}
										/>{' '}
										-{' '}
										<DateOutput
											date={pickupTime}
											timezone={timezone}
											formatter="time"
										/>
									</p>
								</Tooltip>
							</GridContainer>
						);
					}) ?? null
				);
			},
		},
		{
			title: 'Drivers',
			sorting: false,
			render: ({ MealProposalsDeliveryDrivers }) =>
				MealProposalsDeliveryDrivers.map(
					({ DeliveryDriver: { abbreviatedName, id } }) => (
						<Typography key={id} display="block" variant="inherit">
							{abbreviatedName}
						</Typography>
					)
				),
		},
		{
			title: 'Actions',
			sorting: false,
			render: ({ id, SporkLocationId }) => (
				<MealProposalActionMenu
					// TODO: don't use a hack to force MealProposalActionMenu to rerender
					// This is needed because when you change pages or filters on the homepage, Material Table reuses components and the useMachine hook with XState keeps the machine service around for the entire lifecycle of the component.
					// Without this, changing pages or filters results in the previous action menu's machines being used, so the actions aren't guaranteed to be correct for the new Meal Proposal being rendered.
					key={new Date().toISOString()}
					id={id}
					SporkLocationId={SporkLocationId}
				/>
			),
		},
	].map(column => ({
		// we have to disable searching on the front end since it is done in the query
		customFilterAndSearch: () => true,
		// we used to also have customSort: () => true here, but that created issues with sorting by delivery date
		// without that prop, this works just fine and as expected
		defaultSort: orderBy === column.field ? orderDirection : undefined,
		...column,
	}));

export { getColumns };
