import { useMachine } from '@xstate/react';
import { get, omit } from 'lodash-es';

import apolloClient from 'services/apollo';

export default (machine, query, variables, entityPath, options = {}) =>
	useMachine(machine, {
		...omit(options, ['services']),
		services: {
			...options.services,
			updateData: (_context, _event) => callback => {
				const subscription = apolloClient
					.subscribe({
						query,
						variables,
					})
					.subscribe({
						next(data) {
							callback({
								type: 'UPDATE_DATA',
								data: get(data, entityPath),
							});
						},
						error(err) {
							console.error(err);
						},
					});

				return () => subscription.unsubscribe();
			},
		},
	});
