import React from 'react';
import PropTypes from 'prop-types';
import { chunk } from 'lodash-es';

import PrintablePage from 'components/layout/PrintablePage';

import { templateOptions } from 'services/printableLabels';

const getPadding = ({ template }) => {
	switch (template) {
		case templateOptions.AVERY_25395:
			return `0.5in 0.625in`;
		default:
			return 0;
	}
};

const PrintableLabelPage = ({ children, template }) => {
	const templateToGroupCountMap = {
		[templateOptions.AVERY_25395]: 8,
		[templateOptions.STANDARD]: 8,
		[templateOptions.TWO_PER_PAGE]: 2,
	};
	const padding = getPadding({ template });

	// Chunk children into groups for proper page breaks
	return chunk(
		React.Children.toArray(children),
		templateToGroupCountMap[template]
	).map((labels, index) => (
		<PrintablePage key={index} padding={padding}>
			{labels}
		</PrintablePage>
	));
};

PrintableLabelPage.propTypes = {
	template: PropTypes.oneOf(Object.values(templateOptions)),
};

PrintableLabelPage.defaultProps = {
	template: templateOptions.STANDARD,
};

export default PrintableLabelPage;
