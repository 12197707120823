import React, { useContext } from 'react';
import gql from 'graphql-tag';
import Chart from 'react-apexcharts';
import {
	averageHeadCount,
	costPerPerson,
	getPotentialRevenue,
	getSummationCalculation,
	grossMargin,
} from '@sporkbytes/meal-proposal-utils';
import { startCase } from 'lodash-es';

import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

import {
	formatCurrency,
	formatGroupedDate,
	formatPercent,
} from 'services/mealProposal';

const RevenueByTimePeriod = () => {
	const { chartConfig, filters, groupedMealProposalsForCalculations } =
		useContext(AnalyticsContext);

	const options = {
		...chartConfig.options,
		chart: {
			...chartConfig.options.chart,
		},
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: groupedMealProposalsForCalculations.map(
				({ date }) => date
			),
			labels: {
				formatter: value => formatGroupedDate(value, filters?.groupBy),
			},
		},
		yaxis: [
			{
				seriesName: 'Revenue',
				labels: {
					formatter: formatCurrency,
				},
			},
			{
				seriesName: 'Spork Payout',
				labels: {
					formatter: formatCurrency,
				},
			},
			{
				seriesName: 'Gross Margin',
				opposite: true,
				labels: {
					formatter: formatPercent,
				},
			},
			{
				seriesName: 'Meal Count',
				opposite: true,
			},
			{
				seriesName: 'Average Head Count',
				opposite: true,
			},
			{
				seriesName: 'Potential Revenue',
				labels: {
					formatter: formatCurrency,
				},
			},
			{
				seriesName: 'Cost Per Person',
				opposite: true,
				labels: {
					formatter: formatCurrency,
				},
			},
		],
	};

	const series = [
		{
			name: 'Revenue',
			data: groupedMealProposalsForCalculations.map(({ MealProposals }) =>
				getSummationCalculation('totalClientPays', MealProposals)
			),
			type: 'column',
		},
		{
			name: 'Spork Payout',
			data: groupedMealProposalsForCalculations.map(({ MealProposals }) =>
				getSummationCalculation('totalReceivedBySpork', MealProposals)
			),
			type: 'column',
		},
		{
			name: 'Gross Margin',
			data: groupedMealProposalsForCalculations.map(({ MealProposals }) =>
				grossMargin(MealProposals)
			),
		},
		{
			name: 'Meal Count',
			data: groupedMealProposalsForCalculations.map(
				({ MealProposals }) => MealProposals.length
			),
		},
		{
			name: 'Average Head Count',
			data: groupedMealProposalsForCalculations.map(({ MealProposals }) =>
				averageHeadCount(MealProposals)
			),
		},
		{
			name: 'Potential Revenue',
			data: groupedMealProposalsForCalculations.map(({ MealProposals }) =>
				getPotentialRevenue(
					// Calculations break if we don't weed out those that have at least one Meal Option
					MealProposals.filter(
						({ MealOptions }) => MealOptions.length >= 1
					)
				)
			),
			type: 'column',
		},
		{
			name: 'Cost Per Person',
			data: groupedMealProposalsForCalculations.map(({ MealProposals }) =>
				costPerPerson(MealProposals)
			),
		},
	];

	return (
		<>
			<WidgetTitle>Revenue by {startCase(filters?.groupBy)}</WidgetTitle>
			<Chart
				type="line"
				series={series}
				options={options}
				height={chartConfig.height}
			/>
		</>
	);
};

RevenueByTimePeriod.fragments = {
	details: gql`
		fragment RevenueByTimePeriodDetails on MealProposals {
			deliveryDate
			headCount
			MealProposalFees {
				amount
				discount
				type
			}
			MealOptions {
				approved
				gratuity
				PurchaseOrders {
					PurchaseOrderMenuSections {
						PurchaseOrderMenuItems {
							costToClient
							discount
							quantity
						}
					}
				}
			}
		}
	`,
};

export default RevenueByTimePeriod;
