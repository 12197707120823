import { assign, Machine } from 'xstate';
import { isPast, parseISO } from 'date-fns';
import { camelCase } from 'lodash-es';
import {
	canAssignDeliveryDrivers,
	canCancel,
	canDeliver,
	canEdit,
	canRequestClientApproval,
	canRequestClientConfirmation,
	canRequestPartnerApproval,
	canRequestPartnerConfirmation,
	clientHasApproved,
} from '@sporkbytes/meal-proposal-utils';
import { isApproved, isNotApproved } from '@sporkbytes/object-utils';

// GUARDS

const canConvertToDraft = context => !isPast(parseISO(context.deliveryDate));
const canManagerApprove = context =>
	!isPast(parseISO(context.deliveryDate)) &&
	context.MealOptions.length > 0 &&
	context.MealProposalsContacts.length > 0 &&
	isNotApproved(context);
const canPrintLabels = clientHasApproved;
const canCopyPurchaseOrderLink = clientHasApproved;
const canOpenHubSpotDeal = context => !!context.HubSpotDealId;
const canSubmit = context =>
	!isPast(parseISO(context.deliveryDate)) &&
	context.MealOptions.length > 0 &&
	context.MealProposalsContacts.length > 0;
const canViewPhoto = context => !!context.deliveryPhotoUrl;
const matchStatus = status => context => camelCase(context.status) === status;

// EVENTS

const ASSIGN_DELIVERY_DRIVERS = {
	actions: 'assignDeliveryDrivers',
	cond: 'canAssignDeliveryDrivers',
};

const ASSIGN_DELIVERY_GEAR = {
	actions: 'assignDeliveryGear',
};

const ASSIGN_MEAL_CATEGORIES = {
	actions: 'assignMealCategories',
};

const CANCEL = {
	actions: 'cancel',
	cond: 'canCancel',
};

const CONVERT_TO_DRAFT = {
	target: 'convertingToDraft',
	cond: 'canConvertToDraft',
};

const COPY_CLIENT_LINK = {
	actions: 'copyClientLink',
};

const COPY_PO_LINK = {
	actions: 'copyPurchaseOrderLink',
	cond: 'canCopyPurchaseOrderLink',
};

const DELIVER = {
	actions: 'deliver',
	cond: 'canDeliver',
};

const DUPLICATE = {
	actions: 'duplicate',
};

const EDIT = {
	actions: 'edit',
	cond: 'canEdit',
};

const EDIT_PURCHASE_ORDER = {
	actions: 'editPurchaseOrder',
	cond: 'canEdit',
};

const MANAGE_MEAL_OPTIONS = {
	actions: 'manageMealOptions',
	cond: 'canEdit',
};

const MANAGER_APPROVE = {
	actions: 'approve',
	cond: 'canManagerApprove',
};

const MANAGER_DISAPPROVE = {
	actions: 'disapprove',
	cond: 'isApproved',
};

const OPEN_HUB_SPOT_DEAL = {
	actions: 'openHubSpotDeal',
	cond: 'canOpenHubSpotDeal',
};

const PRINT_LABELS = {
	actions: 'printLabels',
	cond: 'canPrintLabels',
};

const REASSIGN_SPORK_CONTACT = {
	actions: 'reassignSporkContact',
};

const REQUEST_CLIENT_APPROVAL = {
	actions: 'requestClientApproval',
	cond: 'canRequestClientApproval',
};

const REQUEST_CLIENT_CONFIRMATION = {
	actions: 'requestClientConfirmation',
	cond: 'canRequestClientConfirmation',
};

const REQUEST_PARTNER_APPROVAL = {
	actions: 'requestPartnerApproval',
	cond: 'canRequestPartnerApproval',
};

const REQUEST_PARTNER_CONFIRMATION = {
	actions: 'requestPartnerConfirmation',
	cond: 'canRequestPartnerConfirmation',
};

const SEND_CORRECTION = {
	actions: 'sendCorrection',
};

const SEND_CLIENT_CANCELLATION_NOTICE = {
	actions: 'sendClientCancellationNotice',
};

const SEND_PARTNER_CANCELLATION_NOTICE = {
	actions: 'sendPartnerCancellationNotice',
	cond: 'clientHasApproved',
};

const SUBMIT = {
	target: 'submitting',
	cond: 'canSubmit',
};

const UPDATE_DATA = {
	actions: assign((context, event) => ({
		...context,
		...event.data,
	})),
};

const VIEW_PHOTO = {
	actions: 'viewPhoto',
	cond: 'canViewPhoto',
};

// STATES

const statuses = (ignoreStatuses = []) =>
	['canceled', 'delivered', 'draft', 'inProgress']
		.filter(status => !ignoreStatuses.includes(status))
		.map(status => ({
			target: status,
			cond: matchStatus(status),
		}));

const canceled = {
	on: {
		DUPLICATE,
		SEND_CLIENT_CANCELLATION_NOTICE,
		SEND_PARTNER_CANCELLATION_NOTICE,
		OPEN_HUB_SPOT_DEAL,
	},
};

const convertingToDraft = {
	invoke: {
		id: 'convertingToDraft',
		src: 'convertToDraft',
		onDone: {
			target: 'draft',
		},
		onError: {
			target: 'inProgress',
		},
	},
};

const delivered = {
	on: {
		DUPLICATE,
		EDIT,
		ASSIGN_DELIVERY_DRIVERS,
		ASSIGN_MEAL_CATEGORIES,
		MANAGE_MEAL_OPTIONS,
		VIEW_PHOTO,
		OPEN_HUB_SPOT_DEAL,
	},
};

const draft = {
	invoke: {
		id: 'updateData',
		src: 'updateData',
	},
	on: {
		// This will return us to the proper state when the UPDATE_DATA event is sent
		'': statuses(['draft']),
		MANAGE_MEAL_OPTIONS,
		DUPLICATE,
		EDIT,
		REASSIGN_SPORK_CONTACT,
		ASSIGN_MEAL_CATEGORIES,
		OPEN_HUB_SPOT_DEAL,
		MANAGER_APPROVE,
		MANAGER_DISAPPROVE,
		SEND_CORRECTION,
		SUBMIT,
		CANCEL,
		UPDATE_DATA,
	},
};

const init = {
	on: {
		'': statuses(),
	},
};

const inProgress = {
	invoke: {
		id: 'updateData',
		src: 'updateData',
	},
	on: {
		// This will return us to the proper state when the UPDATE_DATA event is sent
		'': statuses(['inProgress']),
		MANAGE_MEAL_OPTIONS,
		DUPLICATE,
		EDIT,
		EDIT_PURCHASE_ORDER,
		REASSIGN_SPORK_CONTACT,
		ASSIGN_DELIVERY_DRIVERS,
		ASSIGN_DELIVERY_GEAR,
		ASSIGN_MEAL_CATEGORIES,
		COPY_CLIENT_LINK,
		COPY_PO_LINK,
		OPEN_HUB_SPOT_DEAL,
		PRINT_LABELS,
		REQUEST_CLIENT_APPROVAL,
		REQUEST_CLIENT_CONFIRMATION,
		REQUEST_PARTNER_APPROVAL,
		REQUEST_PARTNER_CONFIRMATION,
		MANAGER_APPROVE,
		MANAGER_DISAPPROVE,
		SEND_CORRECTION,
		CONVERT_TO_DRAFT,
		DELIVER,
		CANCEL,
		UPDATE_DATA,
	},
};

const loading = {
	invoke: {
		id: 'updateData',
		src: 'updateData',
	},
	on: {
		UPDATE_DATA: {
			...UPDATE_DATA,
			target: 'init',
		},
	},
};

const submitting = {
	invoke: {
		id: 'submitting',
		src: 'submit',
		onDone: {
			target: 'inProgress',
		},
		onError: {
			target: 'draft',
		},
	},
};

const states = {
	canceled,
	convertingToDraft,
	delivered,
	draft,
	init,
	inProgress,
	loading,
	submitting,
};

export default (guards = {}) =>
	Machine(
		{
			id: 'mealProposal',
			initial: 'loading',
			states,
		},
		{
			guards: {
				canAssignDeliveryDrivers,
				canCancel,
				canConvertToDraft,
				canCopyPurchaseOrderLink,
				canDeliver,
				canEdit,
				canManagerApprove,
				canOpenHubSpotDeal,
				canPrintLabels,
				canRequestClientApproval,
				canRequestClientConfirmation,
				canRequestPartnerApproval,
				canRequestPartnerConfirmation,
				canSubmit,
				canViewPhoto,
				clientHasApproved,
				isApproved,
				...guards,
			},
		}
	);
