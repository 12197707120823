import React from 'react';
import PropTypes from 'prop-types';
import { Chat, DeviceHub, VerifiedUser } from '@material-ui/icons';
import { getRoles } from '@sporkbytes/api-client/Auth';
import { getAllUsers } from '@sporkbytes/api-client/Crm';
import {
	Form,
	GridContainer,
	LinkedSingleSelect,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';
import { omit } from 'lodash-es';

import UserFormElements, {
	validationObject,
} from 'components/users/UserFormElements';
import LoadingStateContainer from 'components/utilities/LoadingStateContainer';

import useRequest from 'hooks/useRequest';

import * as yup from 'models/validation';

import axios from 'services/data';

const validationSchema = yup.object().shape({
	...omit(validationObject, ['email']),
	emailAddress: yup
		.string()
		.matches(
			/^[A-Za-z0-9._%+-]+@sporkbytes.com$/,
			'Please provide a Spork Bytes email address.'
		)
		.required('Please enter an email address.'),
	Auth0RoleId: yup.string().required('Please select a role.'),
	HubSpotUserId: yup.string(),
});

const UserForm = ({ initialValues, ...props }) => {
	const { data: roles, loading: loadingRoles } = useRequest(() =>
		getRoles(axios)
	);
	const { data: crmUsers, loading: loadingCrmUsers } = useRequest(() =>
		getAllUsers(axios)
	);

	return (
		<LoadingStateContainer loading={loadingRoles || loadingCrmUsers}>
			<Form
				{...props}
				initialValues={initialValues}
				validationSchema={validationSchema}
			>
				{() => (
					<UserFormElements emailAddressFieldName="emailAddress">
						<GridContainer>
							<LinkedTextInput
								name="slackUsername"
								icon={<Chat />}
							/>
							<LinkedSingleSelect
								name="Auth0RoleId"
								label="Role"
								options={roles}
								getOptionKey={role => role.id}
								getOptionLabel={role => role.name}
								getOptionValue={role => role.id}
								icon={<VerifiedUser />}
							/>
							<LinkedSingleSelect
								name="HubSpotUserId"
								label="HubSpot User"
								options={crmUsers}
								getOptionKey={user => user.id}
								getOptionLabel={user => user.name}
								getOptionValue={user => user.id}
								icon={<DeviceHub />}
							/>
						</GridContainer>
					</UserFormElements>
				)}
			</Form>
		</LoadingStateContainer>
	);
};

UserForm.propTypes = {
	initialValues: PropTypes.object,
};

UserForm.defaultProps = {
	initialValues: {},
};

export default UserForm;
