import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { GridContainer } from '@sporkbytes/material-ui-kit-react';

import { UserCard } from './UserCard';

const useStyles = makeStyles(theme => ({
	container: {
		margin: 0,
		padding: 0,
		width: 'auto',
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(2),
		},
	},
}));

const UserList = ({ users }) => {
	const classes = useStyles();

	return (
		<GridContainer columns={3} className={classes.container}>
			{users.map(user => (
				<UserCard key={user.id} {...user} />
			))}
		</GridContainer>
	);
};

UserList.propTypes = {
	users: PropTypes.arrayOf(
		PropTypes.shape({
			active: PropTypes.bool.isRequired,
			emailAddress: PropTypes.string.isRequired,
			fullName: PropTypes.string.isRequired,
			HubSpotUser: PropTypes.string,
			id: PropTypes.string.isRequired,
			role: PropTypes.string.isRequired,
			slackUsername: PropTypes.string,
		})
	),
};

export { UserList };
