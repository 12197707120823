import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { makeStyles, Typography } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import {
	GridContainer,
	LinkedCheckbox,
	LinkedIntegerInput,
	LinkedRadioGroup,
	PrimaryButton,
} from '@sporkbytes/material-ui-kit-react';
import { startCase, uniqBy } from 'lodash-es';

import ExpansionPanel from 'components/content/ExpansionPanel';
import InlineContent from 'components/content/InlineContent';
import MealProposalTableTopper from 'components/meal-proposals/MealProposalTableTopper';
import PageHeader from 'components/content/PageHeader';
import PartnerMenuItemPrintableLabel from 'components/partner-menu-items/PartnerMenuItemPrintableLabel';
import PrintableLabelPage from 'components/printable-labels/PrintableLabelPage';
import PrintButton from 'components/utilities/PrintButton';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';

import * as yup from 'models/validation';

import { templateOptions } from 'services/printableLabels';

const useStyles = makeStyles(theme => ({
	container: {
		marginBottom: theme.spacing(2),
		'@media print': {
			display: 'none',
		},
	},
	expansionPanel: {
		marginBottom: theme.spacing(2),
	},
	setTo0Button: {
		margin: theme.spacing(2, 0),
	},
}));

const PrintSettings = ({ initialValues, title }) => {
	const classes = useStyles();

	// de-duplicate items by ParentPartnerMenuItemId
	const deduplicatedInitialValues = {
		...initialValues,
		groups: initialValues.groups.map(group => ({
			...group,
			items: uniqBy(
				group.items,
				({ ParentPartnerMenuItemId }) => ParentPartnerMenuItemId
			),
		})),
	};

	return (
		<Formik
			initialValues={{
				blankLabels: 0,
				includeBorder: true,
				includePartnerLogo: true,
				includeSporkLogo: true,
				labelQuantity: 0,
				template: templateOptions.STANDARD,
				...deduplicatedInitialValues,
			}}
			validationSchema={yup.object().shape({
				blankLabels: yup.nonNegativeInteger(),
				labelQuantity: yup.nonNegativeInteger(),
			})}
		>
			{({ errors, setFieldValue, values }) => (
				<>
					<div className={classes.container}>
						<PageHeader headerText={title} />
						<ExpansionPanel
							title={
								<>
									<InlineContent>
										<Settings />
									</InlineContent>
									<span>Settings</span>
								</>
							}
							titleProps={{
								component: 'h2',
								variant: 'h5',
							}}
							className={classes.expansionPanel}
						>
							<GridContainer columns={1}>
								<Typography variant="h6" component="h3">
									Display Options
								</Typography>
								<div>
									<LinkedCheckbox name="includeBorder" />
									<LinkedCheckbox name="includePartnerLogo" />
									<LinkedCheckbox name="includeSporkLogo" />
								</div>
								<GridContainer alignItems="center">
									<LinkedRadioGroup
										name="template"
										options={Object.values(templateOptions)}
										getOptionLabel={value =>
											startCase(value)
										}
										row
										onChange={value => {
											setFieldValue('template', value);

											if (
												value ===
												templateOptions.AVERY_25395
											) {
												setFieldValue(
													'includeBorder',
													false
												);
											} else {
												setFieldValue(
													'includeBorder',
													true
												);
											}
										}}
									/>
									<LinkedIntegerInput name="blankLabels" />
								</GridContainer>
								{values.groups && values.groups.length > 0 && (
									<QueryStringSyncedTabs
										id="group"
										tabs={values.groups.map(
											(group, groupIndex) => ({
												id: group.id,
												label: group.name,
												content: (
													<>
														<GridContainer
															alignItems="center"
															columns={2}
														>
															<div>
																{group.tableTopperData && (
																	<LinkedCheckbox
																		name={`groups[${groupIndex}].showTableTopper`}
																		label="Print Table Topper"
																	/>
																)}
															</div>
															<GridContainer>
																<LinkedIntegerInput name="labelQuantity" />
																<PrimaryButton
																	onClick={() => {
																		group.items.forEach(
																			(
																				_item,
																				itemIndex
																			) => {
																				setFieldValue(
																					`groups[${groupIndex}].items[${itemIndex}].quantity`,
																					values.labelQuantity
																				);
																			}
																		);
																	}}
																	className={
																		classes.setTo0Button
																	}
																	disabled={
																		errors.labelQuantity
																	}
																>
																	Set All to{' '}
																	{
																		values.labelQuantity
																	}
																</PrimaryButton>
															</GridContainer>
														</GridContainer>
														{group.items.map(
															(
																item,
																itemIndex
															) => (
																<GridContainer
																	alignItems="flex-end"
																	key={
																		item.id
																	}
																>
																	<Typography>
																		{
																			item.friendlyName
																		}
																	</Typography>
																	<LinkedIntegerInput
																		name={`groups[${groupIndex}].items[${itemIndex}].quantity`}
																		label="Label Quantity"
																	/>
																</GridContainer>
															)
														)}
													</>
												),
											})
										)}
									/>
								)}
							</GridContainer>
						</ExpansionPanel>
						<PrintButton />
					</div>
					{values.groups.map((group, groupIndex) => (
						<React.Fragment key={group.id}>
							{group.showTableTopper && group.tableTopperData && (
								<MealProposalTableTopper
									mealProposal={group.tableTopperData}
								/>
							)}
							<PrintableLabelPage template={values.template}>
								{group.items.map(item =>
									Array.apply(
										null,
										Array(item.quantity || 0)
									).map((value, index) => (
										<PartnerMenuItemPrintableLabel
											key={`${item.id}_${index}`}
											partnerMenuItem={item}
											settings={values}
										/>
									))
								)}
								{/* Output blank labels with the last group */}
								{groupIndex === values.groups.length - 1 &&
									values.blankLabels > 0 &&
									Array.apply(
										null,
										Array(values.blankLabels)
									).map((blankLabel, index) => (
										<PartnerMenuItemPrintableLabel
											key={`blankLabel_${index}`}
											partnerMenuItem={{}}
											settings={{
												...values,
												includePartnerLogo: false,
											}}
										/>
									))}
							</PrintableLabelPage>
						</React.Fragment>
					))}
				</>
			)}
		</Formik>
	);
};

PrintSettings.propTypes = {
	title: PropTypes.string.isRequired,
};

export default PrintSettings;
