import React, { useContext } from 'react';
import gql from 'graphql-tag';
import Chart from 'react-apexcharts';
import { getClientApproved } from '@sporkbytes/meal-option-utils';
import { clientHasApproved } from '@sporkbytes/meal-proposal-utils';
import { totalReceivedByPartner } from '@sporkbytes/purchase-order-utils';
import { groupBy } from 'lodash-es';

import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

import { formatCurrency } from 'services/mealProposal';

const PartnerPayouts = () => {
	const { chartConfig, mealProposalsForCalculations } =
		useContext(AnalyticsContext);

	let purchaseOrders = mealProposalsForCalculations
		.filter(clientHasApproved)
		.map(
			({ MealOptions }) => getClientApproved(MealOptions)?.PurchaseOrders
		)
		.flat();

	purchaseOrders = groupBy(
		purchaseOrders,
		({ PartnerLocation: { Partner } }) => Partner.id
	);

	let partnerPayouts = Object.keys(purchaseOrders)
		.map(partnerId => ({
			name: purchaseOrders[partnerId][0].PartnerLocation.Partner.name,
			payout: totalReceivedByPartner(purchaseOrders[partnerId]),
		}))
		.sort((partnerA, partnerB) => partnerB.payout - partnerA.payout);

	const partnerNames = [];
	const payouts = [];

	partnerPayouts.forEach(({ name, payout }) => {
		partnerNames.push(name);
		payouts.push(payout);
	});

	const options = {
		...chartConfig.options,
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: partnerNames,
		},
		yaxis: {
			labels: {
				formatter: formatCurrency,
			},
		},
	};
	const series = [{ data: payouts, name: 'Payouts' }];

	return (
		<>
			<WidgetTitle>Partner Payouts</WidgetTitle>
			<Chart
				type="bar"
				series={series}
				options={options}
				height={chartConfig.height}
			/>
		</>
	);
};

PartnerPayouts.fragments = {
	details: gql`
		fragment PartnerPayoutsDetails on MealProposals {
			headCount
			MealOptions {
				approved
				PurchaseOrders {
					sporkCommissionPercent
					PartnerLocation {
						Partner {
							id
							name
						}
					}
					PurchaseOrderMenuSections {
						PurchaseOrderMenuItems {
							discount
							paidToPartner
							quantity
						}
					}
					PurchaseOrderPayoutAdjustments {
						amount
					}
				}
			}
		}
	`,
};

export default PartnerPayouts;
