import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		alignItems: 'center',
		gap: '0.25em',
		marginTop: theme.spacing(1),
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	icon: {
		cursor: 'help',
		marginRight: theme.spacing(1),
		verticalAlign: 'middle',
	},
	value: {
		verticalAlign: 'middle',
	},
}));

const UserProperty = ({ Icon, label, value }) => {
	const classes = useStyles();

	return (
		<Typography color="textSecondary" className={classes.container}>
			<Tooltip title={label} className={classes.icon}>
				<Icon color="inherit" fontSize="inherit" />
			</Tooltip>
			<Typography component="span" className={classes.value}>
				{value}
			</Typography>
		</Typography>
	);
};

UserProperty.propTypes = {
	Icon: PropTypes.elementType.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.string,
};

export { UserProperty };
