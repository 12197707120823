import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	ExpansionPanel as MUIExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { uniqueId } from 'lodash-es';

const useStyles = makeStyles(theme => ({
	title: {
		flex: 1,
	},
}));

const ExpansionPanel = ({
	children,
	contentProps,
	title,
	titleProps,
	...props
}) => {
	const [expansionPanelId] = useState(() => uniqueId('expansion-panel'));
	const classes = useStyles();

	return (
		<MUIExpansionPanel {...props}>
			<ExpansionPanelSummary
				expandIcon={<ExpandMore />}
				aria-controls={`${expansionPanelId}-content`}
				id={expansionPanelId}
			>
				<Typography
					variant="inherit"
					className={classes.title}
					component="div"
					{...titleProps}
				>
					{title}
				</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails {...contentProps}>
				{children}
			</ExpansionPanelDetails>
		</MUIExpansionPanel>
	);
};

ExpansionPanel.propTypes = {
	children: PropTypes.node.isRequired,
	contentProps: PropTypes.object,
	title: PropTypes.node.isRequired,
	titleProps: PropTypes.object,
};

export default ExpansionPanel;
