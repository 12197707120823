import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { isCanceled } from '@sporkbytes/meal-proposal-utils';
import { groupBy } from 'lodash-es';

import DeliveryDrivers from 'components/analytics/tabs/DeliveryDrivers';
import Home from 'components/analytics/tabs/Home';
import LoadingPage from 'components/content/LoadingPage';

import { convertHoursToMinutes } from 'services/dates';
import { getFilters, groupMealProposalsByDate } from 'services/mealProposal';
import { groupByArray, not } from 'services/utilities';

const chartConfig = {
	options: {
		chart: {
			fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		},
		colors: [
			'#009688',
			'#f9a825',
			'#512da8',
			'#d32f2f',
			'#43a047',
			'#1976d2',
		],
		plotOptions: {
			bar: {
				dataLabels: {
					position: 'top',
				},
			},
		},
	},
	height: 400,
};

const getMealProposalsQuery = filters => gql`
	query Analytics {
		MealProposals(where: {
			${filters}
			# Ensure every MealProposal has at least one MealOption
			MealOptions: {}
		}) {
			...AnalyticsDeliveryDriversDetails
			...AnalyticsHomeDetails
		}
	}
	${DeliveryDrivers.fragments.details}
    ${Home.fragments.details}
`;

const AnalyticsContext = React.createContext();

const AnalyticsContextProvider = ({ children, queryString }) => {
	const { data, loading } = useQuery(
		getMealProposalsQuery(getFilters(queryString))
	);

	if (loading) {
		return <LoadingPage />;
	}

	const mealProposals = data.MealProposals;
	const mealProposalsForCalculations = mealProposals.filter(not(isCanceled));

	const deliveryTimeRanges = [5.5, 10.5, 14.5, 17.5, 21.5].map(hours =>
		convertHoursToMinutes(hours)
	);

	const mealProposalsByClient = groupByArray(
		mealProposalsForCalculations,
		mealProposal => mealProposal.ClientLocation.Client,
		'id'
	);

	const mealProposalsBySporkContact = groupBy(
		mealProposalsForCalculations,
		mealProposal => mealProposal.SporkContact.emailAddress
	);

	const groupedMealProposals = groupMealProposalsByDate(
		data.MealProposals,
		'deliveryDate',
		queryString?.beginDate,
		queryString?.endDate,
		queryString?.groupBy
	);

	const groupedMealProposalsForCalculations = groupedMealProposals.map(
		({ MealProposals, ...data }) => ({
			...data,
			MealProposals: MealProposals.filter(not(isCanceled)),
		})
	);

	return (
		<AnalyticsContext.Provider
			value={{
				chartConfig,
				deliveryTimeRanges,
				filters: queryString,
				groupedMealProposals,
				groupedMealProposalsForCalculations,
				mealProposals,
				mealProposalsForCalculations,
				mealProposalsByClient,
				mealProposalsBySporkContact,
			}}
		>
			{children}
		</AnalyticsContext.Provider>
	);
};

export { AnalyticsContext, AnalyticsContextProvider };
