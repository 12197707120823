import React from 'react';
import gql from 'graphql-tag';
import { startCase } from 'lodash-es';

import DateOutput from 'components/content/DateOutput';
import DetailPageLink from 'components/routing/DetailPageLink';
import RemoteTable from 'components/content/RemoteTable';

const getEmailActivityLogsQuery = searchTerm => gql`
	query EmailActivityLogs(
        $emailOrderBy: order_by
		$eventOrderBy: order_by
		$timestampOrderBy: order_by
		$orderNumberTextOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		EmailActivityLogs(
			where: {
				_or: [
					{ email: { _ilike: "%${searchTerm}%" } }
					{ event: { _ilike: "%${searchTerm}%" } }
					{ type: { _ilike: "%${searchTerm}%" } }
					{ reason: { _ilike: "%${searchTerm}%" } }
					{ response: { _ilike: "%${searchTerm}%" } }
					{ category: { _ilike: "%${searchTerm}%" } }
				]
			}
			order_by: {
                email: $emailOrderBy
				event: $eventOrderBy
				timestamp: $timestampOrderBy
				MealProposalActivityLog: { MealProposal: { orderNumberText: $orderNumberTextOrderBy } }
			}
			limit: $limit
			offset: $offset
		) {
            email
			event
			type
			timestamp
			reason
			response
			category
			MealProposalActivityLog {
				MealProposal {
					id
					orderNumberText
				}
			}
		}
		EmailActivityLogs_aggregate(
			where: {
				_or: [
					{ email: { _ilike: "%${searchTerm}%" } }
					{ event: { _ilike: "%${searchTerm}%" } }
					{ type: { _ilike: "%${searchTerm}%" } }
					{ reason: { _ilike: "%${searchTerm}%" } }
					{ response: { _ilike: "%${searchTerm}%" } }
					{ category: { _ilike: "%${searchTerm}%" } }
				]
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const EmailActivityLogsTable = () => {
	return (
		<RemoteTable
			columns={[
				{
					title: 'Email',
					field: 'email',
				},
				{
					title: 'Event',
					field: 'event',
					render: ({ event, type }) =>
						startCase(event) +
						(type ? ` (${startCase(type)})` : ''),
				},
				{
					title: 'Order #',
					field: 'orderNumberText',
					render: ({ MealProposalActivityLog }) =>
						MealProposalActivityLog?.MealProposal ? (
							<DetailPageLink
								entityType="MealProposals"
								id={MealProposalActivityLog.MealProposal.id}
								text={
									MealProposalActivityLog.MealProposal
										.orderNumberText
								}
							/>
						) : null,
				},
				{
					title: 'Date',
					field: 'timestamp',
					render: ({ timestamp }) => <DateOutput date={timestamp} />,
				},
				{
					title: 'Response',
					field: 'response',
					sorting: false,
					render: ({ reason, response }) => reason ?? response,
				},
				{
					title: 'Categories',
					field: 'category',
					sorting: false,
					render: ({ category: categories }) =>
						categories ? (
							<ul>
								{JSON.parse(categories).map(category => (
									<li>{category}</li>
								))}
							</ul>
						) : null,
				},
			]}
			defaultOrderBy="timestamp"
			defaultOrderDirection="desc"
			getQuery={getEmailActivityLogsQuery}
			queryEntity="EmailActivityLogs"
		/>
	);
};

export default EmailActivityLogsTable;
