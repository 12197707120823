import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { getRoles } from '@sporkbytes/api-client/Auth';
import { getAllUsers } from '@sporkbytes/api-client/Crm';
import { groupBy, omit, partition } from 'lodash-es';

import useRequest from 'hooks/useRequest';

import axios from 'services/data';

const USERS_PAGE_SUBSCRIPTION = gql`
	subscription UsersPage {
		Users {
			active
			Auth0RoleId
			emailAddress
			fullName
			HubSpotUserId
			id
			slackUsername
		}
	}
`;

/**
 * Filter out users with a role you cannot see,
 * e.g. for Administrators this filters out Data Administrators.
 */
const onlyUsersWithRoles = ({ role }) => !!role;

/**
 * Add data from external sources like Auth0 and HubSpot
 */
const addExternalData =
	({ crmUsersById, rolesById }) =>
	user => ({
		...omit(user, ['__typename', 'Auth0RoleId', 'HubSpotUserId']),
		role: rolesById?.[user.Auth0RoleId]?.[0]?.name,
		HubSpotUser: crmUsersById?.[user.HubSpotUserId]?.[0].name,
	});

const sortAlphabetically = (a, b) => a.fullName.localeCompare(b.fullName);

export const useUsersPage = () => {
	const { data: allUsers, loading: allUsersLoading } = useSubscription(
		USERS_PAGE_SUBSCRIPTION
	);
	const { data: rolesById, loading: loadingRoles } = useRequest(() =>
		getRoles(axios).then(data => groupBy(data, 'id'))
	);
	const { data: crmUsersById, loading: loadingCrmUsers } = useRequest(() =>
		getAllUsers(axios).then(data => groupBy(data, 'id'))
	);
	const allUsersWithAssociatedData =
		allUsers?.Users?.map(addExternalData({ crmUsersById, rolesById }))
			.filter(onlyUsersWithRoles)
			.sort(sortAlphabetically) ?? [];
	const [activeUsers, inactiveUsers] = partition(
		allUsersWithAssociatedData ?? [],
		['active', true]
	);

	return {
		activeUsers,
		inactiveUsers,
		isLoading: allUsersLoading || loadingRoles || loadingCrmUsers,
	};
};
