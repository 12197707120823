import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { startCase } from 'lodash-es';
import { AsyncAutocomplete } from '@sporkbytes/material-ui-kit-react';

import { getSearchResults, navigateToPage, renderOption } from './helpers';

import { useAuth } from 'services/auth';

const useStyles = makeStyles(theme => ({
	autocomplete: {
		color: theme.palette.common.white,
		flex: 1,
		margin: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			maxWidth: 350,
		},
		[theme.breakpoints.up('md')]: {
			maxWidth: 450,
		},
	},
	container: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'flex-end',
		[theme.breakpoints.up('sm')]: {
			marginRight: theme.spacing(1),
		},
	},
}));

const MasterSearch = () => {
	const { userCan } = useAuth();
	const classes = useStyles();
	const history = useHistory();

	return (
		<div className={classes.container}>
			<AsyncAutocomplete
				groupBy={option => startCase(option.type)}
				onChange={navigateToPage({ history, userCan })}
				getSearchResults={getSearchResults}
				renderOption={renderOption({ history, userCan })}
				getOptionLabel={option => option.title}
				className={classes.autocomplete}
				backgroundColor="rgba(255, 255, 255, 0.2)"
				blurOnSelect={true}
			/>
		</div>
	);
};

export default MasterSearch;
