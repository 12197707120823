import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
	approve,
	confirm,
	disapprove,
	undoConfirmation,
} from '@sporkbytes/api-client/PurchaseOrders';
import { canEdit } from '@sporkbytes/purchase-order-utils';
import { canEdit as canEditMealProposal } from '@sporkbytes/meal-proposal-utils';
import { noop } from 'lodash-es';

import { machineEventActionObjects } from 'components/purchase-orders/PurchaseOrderActionMenu/actions/';
import ActionMenu from 'components/utilities/ActionMenu';
import PurchaseOrderReassignContactsDialog from 'components/purchase-orders/PurchaseOrderReassignContactsDialog';

import { createStateEventActions } from 'services/actionMenu';
import { copyText } from 'services/utilities';
import { useAuth } from 'services/auth';
import axios from 'services/data';

import usePurchaseOrderMachine from 'hooks/usePurchaseOrderMachine';
import useQueryString from 'hooks/useQueryString';

import purchaseOrderMachine from 'machines/purchaseOrderMachine';

import { useStore } from 'models/store';

const PurchaseOrderActionMenu = ({ afterDelete, id }) => {
	const history = useHistory();
	const { role, userCan } = useAuth();
	const { showDialog } = useStore();
	const [queryString, setQueryString] = useQueryString();
	const guards = {
		canDuplicate: context =>
			canEditMealProposal(context?.MealOption.MealProposal, role) &&
			context?.MealOption.MealProposal.MealOptions.length > 1,
		canEdit: context => canEdit(context, role),
	};
	const [state, send] = usePurchaseOrderMachine(id, {
		actions: {
			addPayoutAdjustment: () =>
				history.pushNamedRoute('purchaseOrderPayoutAdjustmentsCreate', {
					id,
				}),
			copyPOLink: () =>
				copyText(
					history.getNamedRouteUrl('publicPurchaseOrdersDetail', {
						id,
					})
				),
			delete: noop, // the machine must define something for an event to trigger, so a noop function suffices since our usual delete action will get triggered anyway
			duplicate: noop, // the machine must define something for an event to trigger, so a noop function suffices since our usual duplicate action will get triggered anyway
			edit: () =>
				history.pushNamedRoute('purchaseOrdersEdit', {
					id,
				}),
			reassignContacts: () =>
				showDialog(PurchaseOrderReassignContactsDialog, {
					id,
				}),
		},
		guards,
		services: {
			approve: () => approve(axios, id),
			confirm: () => confirm(axios, id),
			disapprove: () => disapprove(axios, id),
			undoConfirmation: () => undoConfirmation(axios, id),
		},
	});
	const partnerName = state.context?.PartnerLocation?.Partner?.name;

	return (
		<ActionMenu
			options={[
				...createStateEventActions(
					state,
					purchaseOrderMachine(guards),
					send,
					machineEventActionObjects({
						afterDelete,
						id,
						partnerName,
						queryString,
						setQueryString,
						userCan,
					}),
					{ hiddenEvents: ['UPDATE_DATA'], showDivider: false }
				),
			]}
		/>
	);
};

PurchaseOrderActionMenu.propTypes = {
	afterDelete: PropTypes.func,
};

PurchaseOrderActionMenu.defaultProps = {
	afterDelete: () => Promise.resolve(),
};

export default PurchaseOrderActionMenu;
