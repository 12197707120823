import { flow } from 'lodash-es';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import {
	accentedCharacterMap,
	nonAlphaNumeric,
	whitespace,
} from 'services/regexp';

const cleanSearchQuery = flow([
	protoPipe('toLowerCase'),
	stringReplace(
		nonAlphaNumeric,
		char => accentedCharacterMap[char] || char,
		'g'
	),
	stringReplace(nonAlphaNumeric, ' ', 'gi'),
	stringReplace(whitespace, ' ', 'g'),
	protoPipe('trim'),
]);

// a "pipeable" version of string.replace
function stringReplace(pattern, replaceWith, flags) {
	return protoPipe('replace', new RegExp(pattern, flags), replaceWith);
}

// given the name of a function and optional arguments, create a "pipeable" function for any variable's prototype
function protoPipe(fn, ...args) {
	return variable => variable[fn](...args);
}

export const getMultiWordSearchWhereClause = (
	searchTerm,
	searchField = 'searchText'
) =>
	makeCommaSeparatedList(
		cleanSearchQuery(searchTerm)
			.split(' ')
			.filter(word => word !== '')
			.map(word => `{ ${searchField}: { _ilike: "%${word}%" } }`),
		false
	);
