import React, { useContext } from 'react';
import gql from 'graphql-tag';
import Chart from 'react-apexcharts';
import { clientHasApproved } from '@sporkbytes/meal-proposal-utils';
import { getClientApproved } from '@sporkbytes/meal-option-utils';
import { groupBy } from 'lodash-es';

import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

const MealsByPartner = () => {
	const { chartConfig, mealProposalsForCalculations } =
		useContext(AnalyticsContext);

	let purchaseOrders = mealProposalsForCalculations
		.filter(clientHasApproved)
		.map(
			({ MealOptions }) => getClientApproved(MealOptions)?.PurchaseOrders
		)
		.flat();

	purchaseOrders = groupBy(
		purchaseOrders,
		({ PartnerLocation: { Partner } }) => Partner.id
	);

	let partnerNames = [];
	let partnerMealCount = [];

	Object.keys(purchaseOrders)
		.map(partnerId => ({
			name: purchaseOrders[partnerId][0].PartnerLocation.Partner.name,
			mealCount: purchaseOrders[partnerId].length,
		}))
		.sort((partnerA, partnerB) => partnerB.mealCount - partnerA.mealCount)
		.forEach(({ name, mealCount }) => {
			partnerNames.push(name);
			partnerMealCount.push(mealCount);
		});

	const options = {
		...chartConfig.options,
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: partnerNames,
		},
		yaxis: {
			tickAmount: 1,
		},
	};
	const series = [{ data: partnerMealCount, name: 'Number of Meals' }];

	return (
		<>
			<WidgetTitle>Meals by Partner</WidgetTitle>
			<Chart
				type="bar"
				series={series}
				options={options}
				height={chartConfig.height}
			/>
		</>
	);
};

MealsByPartner.fragments = {
	details: gql`
		fragment MealsByPartnerDetails on MealProposals {
			MealOptions {
				approved
				PurchaseOrders {
					PartnerLocation {
						Partner {
							id
							name
						}
					}
				}
			}
		}
	`,
};

export default MealsByPartner;
