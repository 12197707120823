import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Tooltip } from '@material-ui/core';
import { post } from '@sporkbytes/api-client/Bills';
import {
	ConfirmationDialog,
	DateRangeSelector,
} from '@sporkbytes/material-ui-kit-react';
import { getLastPayoutPeriod } from '@sporkbytes/time-utils';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';
import { pick } from 'lodash-es';

import BillMealSelector from 'components/accounting/BillMealSelector';
import CommaSeparatedList from 'components/utilities/CommaSeparatedList';
import DetailPageLink from 'components/routing/DetailPageLink';
import Link from 'components/routing/Link';
import LoadingStateContainer from 'components/utilities/LoadingStateContainer';
import Table from 'components/content/Table';

import { useStore } from 'models/store';

import { createBill, getLineItemForBill } from 'services/accounting';
import axios from 'services/data';

const PARTNER_LOCATIONS_QUERY = gql`
	query UnbilledPartnerLocations(
		$beginDate: timestamptz
		$endDate: timestamptz
	) {
		PartnerLocations(
			order_by: { friendlyName: asc }
			where: {
				PurchaseOrders: {
					BillId: { _is_null: true }
					MealOption: {
						approved: { _eq: true }
						MealProposal: {
							deliveryDate: { _gte: $beginDate, _lte: $endDate }
							status: { _nin: ["Canceled", "Draft"] }
						}
					}
				}
			}
		) {
			AccountingContactId
			id
			friendlyName
			Partner {
				id
				name
			}
			PurchaseOrders(
				order_by: { MealOption: { MealProposal: { orderNumber: asc } } }
				where: {
					BillId: { _is_null: true }
					MealOption: {
						approved: { _eq: true }
						MealProposal: {
							deliveryDate: { _gte: $beginDate, _lte: $endDate }
							status: { _nin: ["Canceled", "Draft"] }
						}
					}
				}
			) {
				...PurchaseOrderBillRequirement
				MealOption {
					MealProposal {
						id
						deliveryDate
						orderNumberText
					}
				}
			}
		}
		AccountingSettings_by_pk(id: "partnerBillMealItemCode") {
			value
		}
	}
	${BillMealSelector.fragments.PurchaseOrderBillRequirement}
`;

const UnbilledPartnerLocationsTable = () => {
	const [dateRange, setDateRange] = useState(getLastPayoutPeriod());
	const { data, loading, refetch } = useQuery(PARTNER_LOCATIONS_QUERY, {
		variables: pick(dateRange, ['beginDate', 'endDate']),
	});
	const { showDialog } = useStore();

	return (
		<LoadingStateContainer height={100} loading={loading}>
			<DateRangeSelector
				defaultDateRange={dateRange}
				onChange={setDateRange}
			/>
			<Table
				data={data?.PartnerLocations ?? []}
				options={{
					paging: false,
					search: false,
					selection: true,
					showSelectAllCheckbox: true,
					showTitle: true,
				}}
				title="Unbilled Partner Locations"
				columns={[
					{
						title: 'Partner - Partner Location',
						field: 'Partner.name',
						defaultSort: 'asc',
						render: ({ id, friendlyName, Partner: { name } }) => {
							const partnerLocation = `${name} - ${friendlyName}`;

							return (
								<Tooltip
									title={`New Bill for ${partnerLocation}`}
								>
									<Link
										permissions={['bills:create']}
										routeName="billsCreate"
										variables={{
											PartnerLocationId: id,
										}}
									>
										{partnerLocation}
									</Link>
								</Tooltip>
							);
						},
					},
					{
						title: 'Meal Proposals',
						sorting: false,
						render: ({ PurchaseOrders }) => (
							<CommaSeparatedList
								items={PurchaseOrders.map(
									({
										MealOption: {
											MealProposal: {
												id,
												orderNumberText,
											},
										},
									}) => (
										<DetailPageLink
											entityType="mealProposals"
											id={id}
											text={orderNumberText}
										/>
									)
								)}
							/>
						),
					},
				]}
				actions={[
					{
						tooltip: 'Create Bills for Selected Partner Locations',
						icon: 'add',
						onClick: (e, selection) => {
							const payload = data.PartnerLocations.filter(
								({ id }) =>
									!!selection.find(
										selectedPartnerLocation =>
											id === selectedPartnerLocation.id
									)
							).map(
								({
									AccountingContactId,
									id,
									Partner,
									PurchaseOrders,
								}) => {
									const partnerLocationBill = createBill(
										{
											contact: {
												contactID: AccountingContactId,
											},
											invoiceNumber:
												makeCommaSeparatedList(
													PurchaseOrders.map(
														({
															MealOption: {
																MealProposal: {
																	orderNumberText,
																},
															},
														}) => orderNumberText
													),
													false
												),
											lineItems: PurchaseOrders.map(
												purchaseOrder =>
													getLineItemForBill(
														purchaseOrder.MealOption
															.MealProposal,
														purchaseOrder,
														data
															.AccountingSettings_by_pk
															.value
													)
											),
										},
										{
											includedMealProposalIds:
												PurchaseOrders.map(
													({
														MealOption: {
															MealProposal: {
																id,
															},
														},
													}) => id
												),
											includedPurchaseOrderIds:
												PurchaseOrders.map(
													({ id }) => id
												),
											PartnerId: Partner.id,
											PartnerLocationId: id,
										}
									);

									return partnerLocationBill;
								}
							);

							showDialog(ConfirmationDialog, {
								text: `Are you sure you want to create ${payload.length} bills?`,
								onConfirmation: async () => {
									await post(axios, payload);

									refetch();
								},
							});
						},
					},
				]}
			/>
		</LoadingStateContainer>
	);
};

export default UnbilledPartnerLocationsTable;
