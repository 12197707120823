import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Tooltip } from '@material-ui/core';
import { DateRangeSelector } from '@sporkbytes/material-ui-kit-react';
import { getYesterday } from '@sporkbytes/time-utils';
import { pick } from 'lodash-es';

import CommaSeparatedList from 'components/utilities/CommaSeparatedList';
import DetailPageLink from 'components/routing/DetailPageLink';
import Link from 'components/routing/Link';
import LoadingStateContainer from 'components/utilities/LoadingStateContainer';
import Table from 'components/content/Table';

const CLIENT_LOCATIONS_QUERY = gql`
	query UninvoicedClientLocations(
		$beginDate: timestamptz
		$endDate: timestamptz
	) {
		ClientLocations(
			order_by: { friendlyName: asc }
			where: {
				MealProposals: {
					InvoiceId: { _is_null: true }
					deliveryDate: { _gte: $beginDate, _lte: $endDate }
					status: { _nin: ["Canceled", "Draft"] }
					MealOptions: { approved: { _eq: true } }
				}
			}
		) {
			id
			friendlyName
			Client {
				name
			}
			MealProposals(
				order_by: { orderNumber: asc }
				where: {
					InvoiceId: { _is_null: true }
					deliveryDate: { _gte: $beginDate, _lte: $endDate }
					status: { _nin: ["Canceled", "Draft"] }
					MealOptions: { approved: { _eq: true } }
				}
			) {
				id
				orderNumberText
			}
		}
	}
`;

const UninvoicedClientLocationsTable = () => {
	const [dateRange, setDateRange] = useState(getYesterday());
	const { data, loading } = useQuery(CLIENT_LOCATIONS_QUERY, {
		variables: pick(dateRange, ['beginDate', 'endDate']),
	});

	return (
		<LoadingStateContainer height={100} loading={loading}>
			<DateRangeSelector
				defaultDateRange={dateRange}
				onChange={setDateRange}
			/>
			<Table
				data={data?.ClientLocations.map(
					({
						Client: { name },
						id,
						friendlyName,
						MealProposals,
					}) => ({
						id,
						clientLocation: `${name} - ${friendlyName}`,
						mealProposals: (
							<CommaSeparatedList
								items={MealProposals.map(
									({ id, orderNumberText }) => (
										<DetailPageLink
											entityType="mealProposals"
											id={id}
											text={orderNumberText}
										/>
									)
								)}
							/>
						),
					})
				)}
				options={{
					paging: false,
					search: false,
					showTitle: true,
				}}
				title="Uninvoiced Client Locations"
				columns={[
					{
						title: 'Client - Client Location',
						field: 'clientLocation',
						defaultSort: 'asc',
						render: ({ id, clientLocation }) => (
							<Tooltip
								title={`New Invoice for ${clientLocation}`}
							>
								<Link
									permissions={['invoices:create']}
									routeName="invoicesCreate"
									variables={{ ClientLocationId: id }}
								>
									{clientLocation}
								</Link>
							</Tooltip>
						),
					},
					{
						title: 'Meal Proposals',
						field: 'mealProposals',
						sorting: false,
					},
				]}
			/>
		</LoadingStateContainer>
	);
};

export default UninvoicedClientLocationsTable;
