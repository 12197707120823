import React from 'react';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core';
import { clientHasApproved } from '@sporkbytes/meal-proposal-utils';
import { partition } from 'lodash-es';

import { useDashboardDay } from 'pages/dashboard/DashboardDayContext';
import CollapsedMealProposal from 'pages/dashboard/CollapsedMealProposal';
import ExpandedMealProposal from 'pages/dashboard/ExpandedMealProposal';

const useStyles = makeStyles(theme => ({
	container: {
		flex: 1,
		overflowX: 'hidden',
		overflowY: 'scroll',
	},
}));

const DashboardDayMealProposals = () => {
	const { expanded, mealProposals } = useDashboardDay();
	const classes = useStyles({ expanded });

	const [approvedMealProposals, unapprovedMealProposals] = partition(
		mealProposals,
		clientHasApproved
	);

	return (
		<div className={classes.container}>
			{[...approvedMealProposals, ...unapprovedMealProposals].map(
				mealProposal =>
					expanded ? (
						<ExpandedMealProposal
							key={mealProposal.id}
							mealProposal={mealProposal}
						/>
					) : (
						<CollapsedMealProposal
							key={mealProposal.id}
							mealProposal={mealProposal}
						/>
					)
			)}
		</div>
	);
};

DashboardDayMealProposals.fragments = {
	details: gql`
		fragment DashboardDayMealProposals on MealProposals {
			id
			MealOptions(where: { approved: { _eq: true } }) {
				approved
			}
			...CollapsedMealProposal
			...ExpandedMealProposal
		}
		${CollapsedMealProposal.fragments.details}
		${ExpandedMealProposal.fragments.details}
	`,
};

export default DashboardDayMealProposals;
