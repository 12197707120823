import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Typography,
} from '@material-ui/core';
import { Chat, DeviceHub, Email, VerifiedUser } from '@material-ui/icons';

import { useAuth } from 'services/auth';

import { UserProperty } from './UserProperty';
import { ToggleActiveButton } from './ToggleActiveButton';

const UserCard = ({
	active,
	emailAddress,
	fullName,
	HubSpotUser,
	id,
	role,
	slackUsername,
}) => {
	const { userCan } = useAuth();
	const { pushNamedRoute } = useHistory();

	return (
		<Card>
			<CardContent>
				<Typography gutterBottom variant="h5" component="h2">
					{fullName}
				</Typography>
				<UserProperty Icon={VerifiedUser} label="Role" value={role} />
				<UserProperty Icon={Email} label="Email" value={emailAddress} />
				<UserProperty
					Icon={Chat}
					label="Slack Username"
					value={slackUsername ?? 'No Slack Username'}
				/>
				<UserProperty
					Icon={DeviceHub}
					label="HubSpot User"
					value={HubSpotUser ?? 'No HubSpot User'}
				/>
			</CardContent>
			<CardActions>
				{userCan(['users:edit']) && (
					<Button
						size="small"
						color="primary"
						onClick={() => pushNamedRoute('usersEdit', { id })}
					>
						Edit
					</Button>
				)}
				<ToggleActiveButton active={active} id={id} />
			</CardActions>
		</Card>
	);
};

UserCard.propTypes = {
	active: PropTypes.bool.isRequired,
	emailAddress: PropTypes.string.isRequired,
	fullName: PropTypes.string.isRequired,
	HubSpotUser: PropTypes.string,
	id: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
	slackUsername: PropTypes.string,
};

export { UserCard };
