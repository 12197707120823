import {
	addDays,
	addMonths,
	addQuarters,
	addWeeks,
	format,
	isBefore,
	startOfDay,
	startOfMonth,
	startOfQuarter,
	startOfWeek,
} from 'date-fns';
import numeral from 'numeral';
import { getValidDate } from '@sporkbytes/time-utils';
import { groupBy } from 'lodash-es';

import { store } from 'models/store';

import { getMultiWordSearchWhereClause } from 'services/search';

const STATUS_OPTIONS = ['Draft', 'In Progress', 'Delivered', 'Canceled'];

export {
	formatCurrency,
	formatGroupedDate,
	formatNumber,
	formatPercent,
	getFilters,
	groupMealProposalsByDate,
	STATUS_OPTIONS,
};

function formatCurrency(value) {
	return numeral(value).format('$0,0.00');
}

function formatGroupedDate(date, group) {
	// TODO: figure out why some charts, like RollingRevenueOverTime, send undefined values for categories, even though there aren't any...maybe an Apex Charts bug?
	if (!date) {
		return date;
	}

	let dateFormat;

	switch (group) {
		case 'quarter':
			dateFormat = 'QQQ yyyy';
			break;
		case 'month':
			dateFormat = `MMM yy`;
			break;
		case 'day':
			dateFormat = 'ccc MMM do';
			break;
		default:
			dateFormat = 'yyyy-MM-dd';
			break;
	}

	return format(getValidDate(date), dateFormat);
}

function formatNumber(amount, { decimalPlaces = 0 } = {}) {
	let format = '0,0';

	if (decimalPlaces > 0) {
		const decimalsArray = new Array(decimalPlaces).fill('0');

		format = `${format}.${decimalsArray.join('')}`;
	}

	return numeral(amount).format(format);
}

function formatPercent(amount) {
	return `${amount}%`;
}

function getFilters({
	beginDate,
	CancellationReasonId,
	ClientId,
	ClientLocationId,
	createdBy,
	endDate,
	filteringBy,
	MealCategoryIds,
	PartnerId,
	searchTerm,
	SporkContactId,
	status,
	unapprovedBy,
}) {
	const filters = [
		_getDateFilter({ beginDate, endDate, filteringBy }),
		_getColumnLevelFilter('CancellationReasonId', CancellationReasonId),
		_getClientFilter(ClientId),
		_getColumnLevelFilter('ClientLocationId', ClientLocationId),
		_getColumnLevelFilter('createdBy', createdBy),
		_getMealCategoryFilter(MealCategoryIds),
		_getPartnerFilter(PartnerId),
		_getSearchTermFilter(searchTerm),
		_getColumnLevelFilter('SporkContactId', SporkContactId),
		_getSporkLocationFilter(),
		_getStatusFilter(status),
		_getUnapprovedByFilter(unapprovedBy),
	].map(filter => `{${filter}}`);

	return `_and: [${filters}]`;
}

function groupMealProposalsByDate(
	mealProposals,
	dateType,
	beginDate,
	endDate,
	timePeriod
) {
	const { startOfTimePeriod } = _getDateHelpersForTimePeriod(timePeriod);

	const dateRange = _getDateRange(beginDate, endDate, timePeriod);

	let groupedMealProposals = groupBy(mealProposals, meal =>
		startOfTimePeriod(new Date(meal[dateType]))
	);

	groupedMealProposals = dateRange.map(date => ({
		date: _formatDate(date),
		MealProposals: groupedMealProposals[date] || [],
	}));

	return groupedMealProposals;
}

function _formatDate(date) {
	return format(date, 'yyyy-MM-dd');
}

function _getClientFilter(ClientId) {
	return ClientId
		? `ClientLocation: {
		ClientId: {
			_eq: "${ClientId}"
		}
	}`
		: '';
}

function _getColumnLevelFilter(name, value) {
	return value ? `${name}: { _eq: "${value}"}` : '';
}

function _getDateFilter({ beginDate, endDate, filteringBy }) {
	if (beginDate || endDate) {
		return `
			${filteringBy}: { 
				${beginDate ? `_gte: "${beginDate.toISOString()}"` : ''}, 
				${endDate ? `_lte: "${endDate.toISOString()}"` : ''}
			}
		`;
	}

	return '';
}

function _getDateHelpersForTimePeriod(timePeriod) {
	const [startOfTimePeriod, addTimePeriods] = {
		day: [startOfDay, addDays],
		week: [startOfWeek, addWeeks],
		month: [startOfMonth, addMonths],
		quarter: [startOfQuarter, addQuarters],
	}[timePeriod];

	return { startOfTimePeriod, addTimePeriods };
}

function _getDateRange(beginDate, endDate, timePeriod) {
	const { startOfTimePeriod, addTimePeriods } =
		_getDateHelpersForTimePeriod(timePeriod);
	const startOfRange = startOfTimePeriod(beginDate);
	const endOfRange = startOfTimePeriod(endDate);

	let dateRange = [startOfRange];
	let lastDateInRange = startOfRange;

	while (isBefore(lastDateInRange, endOfRange)) {
		const newLastDateInRange = addTimePeriods(lastDateInRange, 1);

		dateRange = [...dateRange, newLastDateInRange];
		lastDateInRange = newLastDateInRange;
	}

	return dateRange;
}

function _getMealCategoryFilter(MealCategoryIds) {
	return MealCategoryIds?.length > 0
		? `MealProposalsMealCategories: {
		MealCategoryId: {
			_in: [${MealCategoryIds.map(id => `"${id}"`).join(',')}]
		}
	}
	`
		: '';
}

function _getPartnerFilter(PartnerId) {
	return PartnerId
		? `MealOptions: { 
		approved: { _eq: true }
		PurchaseOrders: { 
			PartnerLocation: { 
				PartnerId: { 
					_eq: "${PartnerId}"
				} 
			} 
		} 
	}`
		: '';
}

function _getSearchTermFilter(searchTerm) {
	return searchTerm
		? ` _or: [
		{ orderNumberText: { _ilike: "%${searchTerm}%" } }
		{ 
			MealOptions: { 
				approved: { _eq: true }
				PurchaseOrders: { 
					PartnerLocation: { 
						Partner: { 
							_and: [${getMultiWordSearchWhereClause(searchTerm)}] 
						} 
					} 
				} 
			} 
		}
		{ 
			ClientLocation: { 
				_and: [${getMultiWordSearchWhereClause(searchTerm)}] 
			} 
		}
		{ 
			ClientLocation: { 
				Client: { 
					_and: [${getMultiWordSearchWhereClause(searchTerm)}] 
				}
			} 
		}
	]`
		: '';
}

function _getSporkLocationFilter() {
	const SporkLocationIds = store.getState().filterBySporkLocations;

	return SporkLocationIds?.length > 0
		? `SporkLocationId: { _in: [${SporkLocationIds.map(
				id => `"${id}"`
		  ).join(',')}] }`
		: '';
}

function _getStatusFilter(status) {
	return status?.length > 0
		? `status: { _in: [${status.map(state => `"${state}"`)}] }`
		: '';
}

function _getUnapprovedByFilter(unapprovedBy) {
	switch (unapprovedBy) {
		case 'Partner':
			return `MealOptions: { 
				approved: { _eq: true }
				PurchaseOrders: { approved: { _eq: false } } 
			}`;
		case 'Client':
			return `_not: { MealOptions: { approved: { _eq: true } } }`;
		case 'Manager':
			return `approved: { _eq: false }`;
		default:
			return '';
	}
}
