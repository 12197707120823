import { get } from 'lodash-es';
import gql from 'graphql-tag';

import PurchaseOrderSelectionDialog from 'components/purchase-orders/PurchaseOrderSelectionDialog';

import { store } from 'models/store';

import apolloClient from 'services/apollo';

const MEAL_PROPOSAL_QUERY = gql`
	query PurchaseOrderSelection(
		$approved: [Boolean!]
		$MealProposalId: uuid!
	) {
		MealProposals_by_pk(id: $MealProposalId) {
			MealOptions(
				where: { approved: { _in: $approved } }
				order_by: { approved: desc }
			) {
				approved
				confirmed
				id
				name
				PurchaseOrders(order_by: { pickupTime: asc }) {
					id
					pickupTime
					PartnerLocation {
						timezone
						Partner {
							logoUrl
							name
						}
					}
				}
			}
		}
	}
`;

export default async ({
	getApprovedMealOptions = false,
	MealProposalId,
	onPurchaseOrderSelect,
	setLoading,
} = {}) => {
	setLoading(true);

	const { data } = await apolloClient.query({
		query: MEAL_PROPOSAL_QUERY,
		variables: {
			approved: getApprovedMealOptions ? [true] : [true, false],
			MealProposalId,
		},
	});

	const mealOptions = get(data, 'MealProposals_by_pk.MealOptions', []);
	const purchaseOrders = mealOptions.flatMap(
		({ PurchaseOrders }) => PurchaseOrders
	);

	if (purchaseOrders.length === 1) {
		onPurchaseOrderSelect(purchaseOrders[0].id);
	} else {
		store.getState().showDialog(PurchaseOrderSelectionDialog, {
			onSelect: onPurchaseOrderSelect,
			mealOptions,
		});
	}

	setLoading(false);
};
