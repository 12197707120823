import React, { useState } from 'react';
import { startCase } from 'lodash-es';
import { Grid, makeStyles } from '@material-ui/core';
import { SingleSelect } from '@sporkbytes/material-ui-kit-react';

import ClientLocationPublicMealsProfile from 'components/client-locations/ClientLocationPublicMealsProfile';
import PageSubheader from 'components/content/PageSubheader';

const useStyles = makeStyles(theme => ({
	singleSelect: {
		fontSize: 24,
		minWidth: 175,
	},
}));

const ClientLocationMenuProfile = () => {
	const [timeline, setTimeline] = useState('1week');
	const classes = useStyles();

	return (
		<ClientLocationPublicMealsProfile
			timeline={timeline}
			subheader={streetAddress => (
				<>
					<Grid container justify="center">
						<Grid item>
							<SingleSelect
								value={timeline}
								onChange={setTimeline}
								label=""
								options={['1week', '1month', '3months'].map(
									timeline => ({
										value: timeline,
										label: startCase(timeline),
									})
								)}
								getOptionValue={option => option.value}
								getOptionKey={option => option.value}
								getOptionLabel={option => option.label}
								inputProps={{
									className: classes.singleSelect,
								}}
							/>
						</Grid>
					</Grid>
					<PageSubheader>
						of upcoming menus for {streetAddress}
					</PageSubheader>
				</>
			)}
			mealLinkProps={mealOption => ({
				routeName: 'publicMealMenu',
				variables: {
					mealMenuId: mealOption.mealMenuId,
				},
			})}
			noMealsFoundText={`There are no meals planned for the next ${startCase(
				timeline
			).toLowerCase()}.`}
		/>
	);
};

export default ClientLocationMenuProfile;
