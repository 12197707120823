import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import ReactS3Uploader from 'react-s3-uploader';
import { getS3SignedUrl } from '@sporkbytes/api-client/Aws';
import {
	PrimaryButton,
	useFormHelpers,
} from '@sporkbytes/material-ui-kit-react';
import { uniqueId } from 'lodash-es';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import axios from 'services/data';

const useStyles = makeStyles(theme => ({
	image: {
		maxWidth: '80%',
	},
	label: {
		marginBottom: theme.spacing(2),
		textAlign: 'center',
	},
	reactS3Uploader: {
		display: 'none',
	},
}));

const getSignedUrlForS3Upload = path => (file, callback) => {
	const params = {
		// whitespace characters can lead to issues with our APIs and builds
		objectName: file.name.replace(/\s+/g, '-') || '',
		contentType: file.type,
		path,
		acl: 'public-read',
		uniquePrefix: true,
	};

	getS3SignedUrl(axios, params).then(callback).catch(console.error);
};

const S3Upload = ({ accept, disabled, label, onChange, path, value }) => {
	const classes = useStyles();
	const [progress, setProgress] = useState(undefined);
	const [inputId] = useState(() => uniqueId('s3Upload_'));

	const isUploading = typeof progress === 'number';

	return (
		<Grid container direction="column" alignContent="center">
			<ReactS3Uploader
				getSignedUrl={getSignedUrlForS3Upload(path)}
				signingUrlMethod="GET"
				accept={accept}
				id={inputId}
				className={classes.reactS3Uploader}
				onProgress={setProgress}
				onFinish={({ publicUrl }) => {
					setProgress(undefined);
					onChange(publicUrl);
				}}
				disabled={disabled}
			/>
			<label htmlFor={inputId} className={classes.label}>
				<PrimaryButton component="span" disabled={disabled}>
					{label}
				</PrimaryButton>
			</label>
			{isUploading && (
				<CenteredCircularProgress
					variant="determinate"
					value={progress}
				/>
			)}
			{!isUploading && value && (
				<img src={value} className={classes.image} alt={label} />
			)}
		</Grid>
	);
};

S3Upload.propTypes = {
	accept: PropTypes.string,
	disabled: PropTypes.bool,
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	path: PropTypes.string.isRequired,
	value: PropTypes.string,
};

S3Upload.defaultProps = {
	accept: 'image/png, image/jpeg',
};

const LinkedS3Upload = props => {
	const helpers = useFormHelpers(props);

	return <S3Upload {...helpers} {...props} />;
};

LinkedS3Upload.propTypes = {
	name: PropTypes.string.isRequired,
};

export { S3Upload, LinkedS3Upload };
