import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { hasIncludedItems } from '@sporkbytes/purchase-order-utils';
import { uniqBy } from 'lodash-es';

import Logo from 'components/content/Logo';
import RichText from 'components/content/RichText';

const useStyles = makeStyles(theme => ({
	container: {
		'& + &': {
			marginTop: theme.spacing(8),
		},
	},
	menuSectionName: {
		margin: theme.spacing(4, 0, 2, 0),
		textDecoration: 'underline',
	},
	purchaseOrderMenuItem: {
		margin: theme.spacing(2, 0, 0, 1),
		pageBreakInside: 'avoid',
	},
	richTextContent: {
		marginLeft: theme.spacing(1),
	},
}));

const PurchaseOrderPrintableMenu = ({
	purchaseOrder: {
		PartnerLocation: { Partner },
		...purchaseOrder
	},
	showLogo,
}) => {
	const classes = useStyles();
	const deduplicatePartnerMenuItems = ({
		PurchaseOrderMenuItems,
		...purchaseOrderMenuSection
	}) => ({
		...purchaseOrderMenuSection,
		PurchaseOrderMenuItems: uniqBy(
			PurchaseOrderMenuItems,
			({ PartnerMenuItem }) => PartnerMenuItem.ParentPartnerMenuItemId
		),
	});

	return hasIncludedItems(purchaseOrder) ? (
		<section className={classes.container}>
			{showLogo && (
				<Grid container justify="center">
					<Logo url={Partner.logoUrl} alt={Partner.name} />
				</Grid>
			)}
			{purchaseOrder.PurchaseOrderMenuSections.filter(
				({ PurchaseOrderMenuItems }) =>
					PurchaseOrderMenuItems.length > 0
			)
				.map(deduplicatePartnerMenuItems)
				.map(({ id, name, PurchaseOrderMenuItems }) => (
					<React.Fragment key={id}>
						<Typography
							component="h3"
							variant="h4"
							className={classes.menuSectionName}
						>
							{name}
						</Typography>
						{PurchaseOrderMenuItems.map(
							({
								id,
								clientComment,
								partnerComment,
								PartnerMenuItem: {
									friendlyName,
									description,
									DietaryTags,
								},
							}) => (
								<div
									key={id}
									className={classes.purchaseOrderMenuItem}
								>
									<Typography component="h4" variant="h6">
										{friendlyName}
										{DietaryTags.length > 0 &&
											` (${DietaryTags.map(
												({ name }) => name
											).join(', ')})`}
									</Typography>
									{[
										description,
										clientComment,
										partnerComment,
									].map(
										(richText = null, index) =>
											richText && (
												<RichText
													key={index}
													body={richText}
													ContentComponent={props => (
														<Typography
															className={
																classes.richTextContent
															}
															variant="body2"
															{...props}
														/>
													)}
												/>
											)
									)}
								</div>
							)
						)}
					</React.Fragment>
				))}
		</section>
	) : null;
};

PurchaseOrderPrintableMenu.propTypes = {
	purchaseOrder: PropTypes.object.isRequired,
	showLogo: PropTypes.bool,
};

PurchaseOrderPrintableMenu.defaultProps = {
	showLogo: true,
};

export default PurchaseOrderPrintableMenu;
