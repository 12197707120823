import React, { useContext, useState } from 'react';
import gql from 'graphql-tag';
import Chart from 'react-apexcharts';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
	averageHeadCount,
	getSummationCalculation,
	grossMargin,
} from '@sporkbytes/meal-proposal-utils';
import { getPercent } from '@sporkbytes/math-utils';
import { SingleSelect } from '@sporkbytes/material-ui-kit-react';
import { startCase } from 'lodash-es';

import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

import {
	formatCurrency,
	formatNumber,
	formatPercent,
} from 'services/mealProposal';

const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 100,
		width: '100%',
	},
}));

const RevenueByClient = () => {
	const [percentage, setPercentage] = useState(80);
	const [orderBy, setOrderBy] = useState('revenue');
	const classes = useStyles();
	const { chartConfig, mealProposalsByClient, mealProposalsForCalculations } =
		useContext(AnalyticsContext);

	let clientData = mealProposalsByClient.map(client => ({
		name: client.key.name,
		paid: getSummationCalculation('totalClientPays', client.values),
		receivedBySpork: getSummationCalculation(
			'totalReceivedBySpork',
			client.values
		),
		grossMargin: grossMargin(client.values),
		mealCount: client.values.length,
		averageHeadCount: averageHeadCount(client.values),
	}));

	switch (orderBy) {
		case 'sporkPayout':
		case 'grossMargin':
			clientData.sort(
				(clientA, clientB) =>
					clientB.receivedBySpork - clientA.receivedBySpork
			);
			break;
		case 'revenue':
		case 'mealCount':
		default:
			clientData.sort((clientA, clientB) => clientB.paid - clientA.paid);
			break;
	}

	const { totalRevenue, totalReceivedBySpork } = clientData.reduce(
		(
			{ totalRevenue, totalReceivedBySpork },
			{ paid, receivedBySpork }
		) => ({
			totalRevenue: totalRevenue + paid,
			totalReceivedBySpork: totalReceivedBySpork + receivedBySpork,
		}),
		{ totalRevenue: 0, totalReceivedBySpork: 0 }
	);

	const clientChartData = {
		clientNames: [],
		revenue: [],
		sporkPayout: [],
		grossMargin: [],
		mealCount: [],
		averageHeadCount: [],
	};
	let currentPercent = 0;
	let lastClientIndex = 0;
	let shownMealCount = 0;

	clientData.forEach(({ mealCount, paid, receivedBySpork }, index) => {
		if (currentPercent <= percentage) {
			lastClientIndex = index;
			currentPercent += getPercent(
				orderBy === 'revenue' ? paid : receivedBySpork,
				orderBy === 'revenue' ? totalRevenue : totalReceivedBySpork
			);
			shownMealCount += mealCount;
		}
	});

	const clientsTotalCount = clientData.length;

	const reSortData = [
		'grossMargin',
		'mealCount',
		'averageHeadCount',
	].includes(orderBy);

	if (reSortData) {
		clientData = clientData.slice(0, lastClientIndex + 1);

		clientData.sort(
			(clientA, clientB) => clientB[orderBy] - clientA[orderBy]
		);
	}

	for (let i = 0; i < lastClientIndex; i++) {
		const {
			mealCount,
			name,
			paid,
			receivedBySpork,
			grossMargin,
			averageHeadCount,
		} = clientData[i];

		clientChartData.clientNames.push(name);
		clientChartData.revenue.push(paid);
		clientChartData.sporkPayout.push(receivedBySpork);
		clientChartData.grossMargin.push(grossMargin);
		clientChartData.mealCount.push(mealCount);
		clientChartData.averageHeadCount.push(averageHeadCount);
	}

	const clientsShownCount = clientChartData.clientNames.length;

	const options = {
		...chartConfig.options,
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: clientChartData.clientNames,
		},
		yaxis: [
			{
				seriesName: 'Revenue',
				labels: {
					formatter: formatCurrency,
				},
			},
			{
				seriesName: 'Spork Payout',
				opposite: true,
				labels: {
					formatter: formatCurrency,
				},
			},
			{
				seriesName: 'Gross Margin',
				opposite: true,
				labels: {
					formatter: formatPercent,
				},
			},
			{
				seriesName: 'Meal Count',
				labels: {
					formatter: formatNumber,
				},
			},
			{
				seriesName: 'Average Head Count',
				labels: {
					formatter: formatNumber,
				},
			},
		],
	};

	const series = [
		{
			data: clientChartData.revenue,
			name: 'Revenue',
			type: 'column',
		},
		{
			data: clientChartData.sporkPayout,
			name: 'Spork Payout',
			type: 'column',
		},
		{
			data: clientChartData.grossMargin,
			name: 'Gross Margin',
		},
		{
			data: clientChartData.mealCount,
			name: 'Meal Count',
		},
		{
			data: clientChartData.averageHeadCount,
			name: 'Average Head Count',
		},
	];

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} sm>
					<WidgetTitle>Revenue by Client</WidgetTitle>
					<Typography component="p" variant="subtitle1">
						{clientsShownCount} of {clientsTotalCount} clients (
						{formatPercent(
							getPercent(clientsShownCount, clientsTotalCount, 0)
						)}
						) account for{' '}
						{formatPercent(Math.round(currentPercent))} of{' '}
						{orderBy === 'revenue' ? 'revenue' : 'Spork payout'}{' '}
						with {formatNumber(shownMealCount)} (
						{formatPercent(
							getPercent(
								shownMealCount,
								mealProposalsForCalculations.length,
								0
							)
						)}
						) meals
					</Typography>
				</Grid>
				<Grid item xs={12} sm="auto">
					<SingleSelect
						value={orderBy}
						onChange={setOrderBy}
						getOptionLabel={startCase}
						options={[
							'revenue',
							'sporkPayout',
							'grossMargin',
							'mealCount',
							'averageHeadCount',
						]}
						label="Order By"
					/>
				</Grid>
				<Grid item xs={12} sm="auto">
					<SingleSelect
						value={percentage}
						onChange={setPercentage}
						getOptionLabel={formatPercent}
						options={[15, 30, 50, 80, 100]}
						label={
							orderBy === 'revenue' ? 'Revenue' : 'Spork Payout'
						}
						className={classes.formControl}
					/>
				</Grid>
			</Grid>
			<Chart
				type="line"
				series={series}
				options={options}
				height={chartConfig.height}
			/>
		</>
	);
};

RevenueByClient.fragments = {
	details: gql`
		fragment RevenueByClientDetails on MealProposals {
			deliveryDate
			headCount
			ClientLocation {
				Client {
					id
					name
				}
			}
			MealProposalFees {
				amount
				discount
				type
			}
			MealOptions {
				approved
				gratuity
				PurchaseOrders {
					PurchaseOrderMenuSections {
						PurchaseOrderMenuItems {
							costToClient
							discount
							quantity
						}
					}
				}
			}
		}
	`,
};

export default RevenueByClient;
