import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import {
	Button,
	DialogActions,
	DialogContent,
	Typography,
} from '@material-ui/core';
import { startCase } from 'lodash-es';
import { Dialog, PrimaryButton } from '@sporkbytes/material-ui-kit-react';

import CommaSeparatedList from 'components/utilities/CommaSeparatedList';
import DateOutput from 'components/content/DateOutput';
import DetailPageLink from 'components/routing/DetailPageLink';
import EmailActivityLogsTable from 'components/meal-proposal-activity-logs/EmailActivityLogsTable';
import EmailTemplatePreviewDialog from 'components/email-templates/EmailTemplatePreviewDialog';
import RemoteTable from 'components/content/RemoteTable';
import RichText from 'components/content/RichText';

import { useStore } from 'models/store';

import { getMultiWordSearchWhereClause } from 'services/search';
import { useAuth } from 'services/auth';

const TYPES = [
	'approval',
	'arrival',
	'cancellation',
	'confirmation',
	'delivery',
	'departure',
	'disapproval',
	'edit',
	'email',
	'note',
	'phone call',
	'pickup',
	'text message',
	'undo confirmation',
];

const getMealProposalActivityLogsQuery = searchTerm => gql`
	query MealProposalActivityLogs(
		$MealProposalId: uuid!
		$updatedAtOrderBy: order_by
		$createdByOrderBy: order_by
		$notesOrderBy: order_by
		$typeOrderBy: order_by
		$typeFilter: [enum_mealproposalactivitylogs_type!]
		$limit: Int!
		$offset: Int!
	) {
		MealProposalActivityLogs(
			where: {
				_or: [
					{ createdBy: { _ilike: "%${searchTerm}%" } }
					{ notes: { _ilike: "%${searchTerm}%" } }
					{
						MealProposalActivityLogsContacts: {
							Contact: { _and: [${getMultiWordSearchWhereClause(searchTerm)}] }
						}
					}
				]
				type: { _in: $typeFilter }
				MealProposalId: { _eq: $MealProposalId }
			}
			order_by: {
				updatedAt: $updatedAtOrderBy
				createdBy: $createdByOrderBy
				notes: $notesOrderBy
				type: $typeOrderBy
			}
			limit: $limit
			offset: $offset
		) {
			id
			updatedAt
			createdBy
			notes
			payload
			type
			MealProposalActivityLogsContacts {
				Contact {
					id
					fullName
				}
			}
		}
		MealProposalActivityLogs_aggregate(
			where: {
				_or: [
					{ createdBy: { _ilike: "%${searchTerm}%" } }
					{ notes: { _ilike: "%${searchTerm}%" } }
					{
						MealProposalActivityLogsContacts: {
							Contact: { _and: [${getMultiWordSearchWhereClause(searchTerm)}] }
						}
					}
				]
				type: { _in: $typeFilter }
				MealProposalId: { _eq: $MealProposalId }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const MealProposalActivityLogsTable = ({ MealProposalId }) => {
	const { userCan } = useAuth();
	const { showDialog } = useStore();

	return (
		<RemoteTable
			columns={[
				{
					title: 'Notes',
					field: 'notes',
					filtering: false,
					render: ({ notes }) => <RichText body={notes} />,
				},
				{
					title: 'Last Updated',
					field: 'updatedAt',
					filtering: false,
					render: ({ updatedAt }) => <DateOutput date={updatedAt} />,
				},
				{
					title: 'Handled By',
					field: 'createdBy',
					filtering: false,
				},
				{
					title: 'Type',
					field: 'type',
					cellStyle: { textAlign: 'center' },
					headerStyle: { textAlign: 'center' },
					lookup: TYPES.reduce(
						(lookup, type) => ({
							...lookup,
							[type]: startCase(type),
						}),
						{}
					),
					render: ({ id, type, payload }) => {
						let primaryContent = (
							<Typography variant="body2">
								{startCase(type)}
							</Typography>
						);
						let secondaryContent;

						switch (type) {
							case 'email':
								if (!!payload) {
									primaryContent = (
										<Button
											onClick={() =>
												showDialog(
													EmailTemplatePreviewDialog,
													{
														emailTemplate: payload,
													}
												)
											}
										>
											{startCase(type)}
										</Button>
									);
								}
								secondaryContent = (
									<Button
										onClick={() =>
											showDialog(props => (
												<Dialog {...props}>
													<EmailActivityLogsTable
														MealProposalActivityLogId={
															id
														}
													/>
													<DialogActions>
														<PrimaryButton
															onClick={
																props.onClose
															}
														>
															Close
														</PrimaryButton>
													</DialogActions>
												</Dialog>
											))
										}
									>
										View Activity
									</Button>
								);
								break;
							case 'edit':
								if (!!payload) {
									primaryContent = (
										<Button
											onClick={() =>
												showDialog(props => (
													<Dialog {...props}>
														<DialogContent>
															{type ===
																'edit' && (
																<RichText
																	title="Edits"
																	body={
																		payload.changesMade
																	}
																/>
															)}
														</DialogContent>
													</Dialog>
												))
											}
										>
											{startCase(type)}
										</Button>
									);
								}
								break;
							default:
						}

						return (
							<>
								{primaryContent}
								{secondaryContent}
							</>
						);
					},
				},
				{
					title: 'Contacts',
					field: 'contacts',
					sorting: false,
					filtering: false,
					render: ({ MealProposalActivityLogsContacts }) => (
						<CommaSeparatedList
							items={MealProposalActivityLogsContacts.map(
								({ Contact: { id, fullName } }) => (
									<DetailPageLink
										key={id}
										entityType="Contacts"
										id={id}
										text={fullName}
									/>
								)
							)}
						/>
					),
				},
			]}
			entityToAddName="Activity Log Entry"
			addNewEntityRouteName="mealProposalActivityLogsCreate"
			addNewEntityRouteVariables={{ id: MealProposalId }}
			canAddNewEntity={userCan([
				'meal-proposals:edit',
				'meal-proposal-activity-logs:create',
			])}
			defaultOrderBy="updatedAt"
			defaultOrderDirection="desc"
			getQuery={getMealProposalActivityLogsQuery}
			queryEntity="MealProposalActivityLogs"
			variables={{ MealProposalId }}
			options={{ filtering: true }}
		/>
	);
};

MealProposalActivityLogsTable.propTypes = {
	MealProposalId: PropTypes.string.isRequired,
};

export default MealProposalActivityLogsTable;
