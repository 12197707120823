import React from 'react';
import { useHistory } from 'react-router-dom';
import {
	GridContainer,
	PrimaryButton,
} from '@sporkbytes/material-ui-kit-react';

import LoadingStateContainer from 'components/utilities/LoadingStateContainer';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';

import { useAuth } from 'services/auth';

import { UserList } from './components';

import { useUsersPage } from './hooks';

const Users = () => {
	const { activeUsers, inactiveUsers, isLoading } = useUsersPage();
	const { userCan } = useAuth();
	const { pushNamedRoute } = useHistory();
	const entityType = 'Users';

	return (
		<Page title={entityType}>
			<PageHeader headerText={entityType} />
			<LoadingStateContainer loading={isLoading}>
				<>
					{userCan(['users:create']) && (
						<GridContainer>
							<PrimaryButton
								onClick={() => pushNamedRoute('usersCreate')}
							>
								Add New User
							</PrimaryButton>
						</GridContainer>
					)}

					<QueryStringSyncedTabs
						id="active"
						tabs={[
							{
								id: 'true',
								label: 'Active',
								content: <UserList users={activeUsers} />,
							},
							{
								id: 'false',
								label: 'Inactive',
								content: <UserList users={inactiveUsers} />,
							},
						]}
					/>
				</>
			</LoadingStateContainer>
		</Page>
	);
};

export default Users;
