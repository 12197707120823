import React, { useContext } from 'react';
import gql from 'graphql-tag';
import Chart from 'react-apexcharts';
import { getHours, getMinutes } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { getPercent } from '@sporkbytes/math-utils';
import { findLastIndex, flow } from 'lodash-es';

import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

import { convertHoursToMinutes, formatMinutes } from 'services/dates';
import { formatPercent } from 'services/mealProposal';

const TIME_SLOT_SIZE = 15;
const MINUTES_IN_A_DAY = convertHoursToMinutes(24);
const TIME_SLOTS_COUNT = MINUTES_IN_A_DAY / TIME_SLOT_SIZE;

const MealsByTimeOfday = () => {
	const { chartConfig, mealProposalsForCalculations } =
		useContext(AnalyticsContext);
	const getPercentOfMeals = flow(getPercent, formatPercent);

	const mealsByTimeSlots = mealProposalsForCalculations
		.map(({ deliveryDate, ClientLocation: { timezone } }) => {
			const date = utcToZonedTime(new Date(deliveryDate), timezone);

			let totalMinutes = convertHoursToMinutes(
				getHours(date),
				getMinutes(date)
			);

			const remainder = totalMinutes % TIME_SLOT_SIZE;

			return totalMinutes - remainder;
		})
		.reduce((mealsByTimeSlots, timeSlot) => {
			mealsByTimeSlots[timeSlot] = mealsByTimeSlots[timeSlot]
				? mealsByTimeSlots[timeSlot] + 1
				: 1;

			return mealsByTimeSlots;
		}, {});

	const timeSlots = Array(TIME_SLOTS_COUNT)
		.fill(0)
		.map((e, index) => index * TIME_SLOT_SIZE);

	let data = timeSlots.map(slot => mealsByTimeSlots[slot] || 0);

	const startIndex = data.findIndex(numberOfMeals => numberOfMeals > 0);

	const endIndex =
		findLastIndex(data, numberOfMeals => numberOfMeals > 0) + 1;

	data = data.slice(startIndex, endIndex);
	const labels = timeSlots.slice(startIndex, endIndex);

	const options = {
		...chartConfig.options,
		chart: {
			...chartConfig.options.chart,
		},
		dataLabels: {
			enabled: false,
		},
		labels,
		xaxis: {
			labels: {
				formatter: formatMinutes,
			},
		},
		yaxis: {
			labels: {
				formatter: numberOfMeals =>
					getPercentOfMeals(
						numberOfMeals,
						mealProposalsForCalculations.length,
						0
					),
			},
		},
		tooltip: {
			x: {
				formatter: minutes =>
					`${formatMinutes(minutes)} - ${formatMinutes(
						minutes + TIME_SLOT_SIZE - 1
					)}`,
			},
			y: {
				formatter: numberOfMeals =>
					`${numberOfMeals} (${getPercentOfMeals(
						numberOfMeals,
						mealProposalsForCalculations.length
					)})`,
			},
		},
	};

	const series = [
		{
			name: 'Number Of Meals',
			data,
		},
	];

	return (
		<>
			<WidgetTitle>Meals by Time Of Day</WidgetTitle>
			<Chart
				type="histogram"
				series={series}
				options={options}
				height={chartConfig.height}
			/>
		</>
	);
};

MealsByTimeOfday.fragments = {
	details: gql`
		fragment MealsByTimeOfDayDetails on MealProposals {
			deliveryDate
			ClientLocation {
				timezone
			}
		}
	`,
};

export default MealsByTimeOfday;
