import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { sortBy, uniqBy } from 'lodash-es';
import { isAfter, addHours } from 'date-fns';
import {
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListSubheader,
	ListItemText,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { Add, RemoveCircle, ImportContacts } from '@material-ui/icons';
import {
	ConfirmationDialog,
	DialogForm,
	GridContainer,
	PrimaryButton,
} from '@sporkbytes/material-ui-kit-react';
import { getIncludedClientContacts } from '@sporkbytes/meal-proposal-utils';
import {
	addExistingContact,
	removeContact,
} from '@sporkbytes/api-client/MealProposals';

import ContactSearch from 'components/contacts/ContactSearch';
import DetailPageLink from 'components/routing/DetailPageLink';
import LoadingStateContainer from 'components/utilities/LoadingStateContainer';
import NoContentText from 'components/content/NoContentText';

import * as yup from 'models/validation';
import { useStore } from 'models/store';

import axios from 'services/data';
import { useAuth } from 'services/auth';

const CONTACTS_QUERY = gql`
	query AssignedAndRecentlyUsedContacts(
		$ClientLocationId: uuid!
		$MealProposalId: uuid!
		$includeRecentlyUsedContacts: Boolean!
	) {
		assignedContacts: Contacts(
			where: {
				MealProposalsContacts: {
					MealProposalId: { _eq: $MealProposalId }
				}
			}
			order_by: { firstName: asc }
		) {
			id
			email
			firstName
			fullName
		}
		MealProposals(
			where: { ClientLocationId: { _eq: $ClientLocationId } }
			order_by: { createdAt: desc }
			limit: 10
		) @include(if: $includeRecentlyUsedContacts) {
			MealProposalsContacts(
				where: {
					_not: {
						Contact: {
							MealProposalsContacts: {
								MealProposalId: { _eq: $MealProposalId }
							}
						}
					}
				}
			) {
				Contact {
					id
					email
					firstName
					lastName
					fullName
				}
			}
		}
	}
`;

const ContactList = ({ title, ...props }) => (
	<List
		subheader={
			<ListSubheader color="inherit">
				<Typography variant="h6" component="h3">
					{title}
				</Typography>
			</ListSubheader>
		}
		{...props}
	/>
);

const ContactListItem = ({
	contact: { id, fullName, email },
	onClick,
	permissions,
	tooltip,
	icon,
}) => {
	const { userCan } = useAuth();

	return (
		<>
			<ListItem key={id}>
				<ListItemText
					primary={
						<DetailPageLink
							entityType="Contacts"
							id={id}
							text={fullName}
						/>
					}
					secondary={email}
				/>
				{userCan(permissions) && (
					<ListItemSecondaryAction>
						<Tooltip title={tooltip}>
							<IconButton
								onClick={() => onClick({ id, fullName })}
							>
								{icon}
							</IconButton>
						</Tooltip>
					</ListItemSecondaryAction>
				)}
			</ListItem>
			<Divider />
		</>
	);
};

const MealProposalContacts = ({
	mealProposal: { ClientLocationId, id: MealProposalId, createdAt },
}) => {
	const includeRecentlyUsedContacts = isAfter(
		addHours(new Date(createdAt), 8),
		new Date()
	);

	const { data, loading, refetch } = useQuery(CONTACTS_QUERY, {
		variables: {
			ClientLocationId,
			MealProposalId,
			includeRecentlyUsedContacts,
		},
		notifyOnNetworkStatusChange: true,
	});
	const { userCannot } = useAuth();
	const { showDialog } = useStore();
	const [assigningContacts, setAssigningContacts] = useState(false);
	const history = useHistory();

	const assignContactDecorator = assignmentFunction => contact => {
		setAssigningContacts(true);

		return assignmentFunction(axios, MealProposalId, contact.id).then(
			() => {
				setAssigningContacts(false);

				return refetch({
					ClientLocationId,
					MealProposalId,
					includeRecentlyUsedContacts,
				});
			}
		);
	};

	const recentlyUsedContacts = sortBy(
		uniqBy(data?.MealProposals?.flatMap(getIncludedClientContacts), 'id'),
		'firstName'
	);

	return (
		<LoadingStateContainer loading={assigningContacts || loading}>
			<GridContainer>
				{includeRecentlyUsedContacts &&
					recentlyUsedContacts.length > 0 && (
						<ContactList title="Recently Used">
							{recentlyUsedContacts.map(contact => (
								<ContactListItem
									key={contact.id}
									contact={contact}
									onClick={assignContactDecorator(
										addExistingContact
									)}
									permissions={[
										'meal-proposals-contacts:create',
									]}
									tooltip={`Add ${contact.firstName}`}
									icon={<Add />}
								/>
							))}
						</ContactList>
					)}
				<ContactList title="Assigned">
					{data?.assignedContacts.length > 0 ? (
						data.assignedContacts.map(contact => (
							<ContactListItem
								key={contact.id}
								contact={contact}
								onClick={contact =>
									showDialog(ConfirmationDialog, {
										text: `Are you sure you want to remove ${contact.fullName} from this meal proposal?`,
										onConfirmation: () =>
											assignContactDecorator(
												removeContact
											)(contact),
									})
								}
								permissions={['meal-proposals-contacts:delete']}
								tooltip={`Remove ${contact.firstName}`}
								icon={<RemoveCircle color="error" />}
							/>
						))
					) : (
						<NoContentText>No Contacts Assigned</NoContentText>
					)}
				</ContactList>
			</GridContainer>
			<GridContainer justify="flex-end" columns="none">
				<PrimaryButton
					size="small"
					disabled={userCannot([
						'contacts:create',
						'meal-proposals-contacts:create',
					])}
					onClick={() =>
						history.pushNamedRoute('mealProposalsContactsCreate', {
							id: MealProposalId,
						})
					}
					startIcon={<Add />}
				>
					Add New Contact
				</PrimaryButton>
				<PrimaryButton
					size="small"
					startIcon={<ImportContacts />}
					disabled={userCannot(['meal-proposals-contacts:create'])}
					onClick={() =>
						showDialog(props => (
							<DialogForm
								title="Search for a Contact"
								validationSchema={yup.object().shape({
									contact: yup
										.object()
										.required('Please select a Contact.'),
								})}
								onSubmit={({ contact }) =>
									assignContactDecorator(addExistingContact)(
										contact
									)
								}
								{...props}
							>
								{() => (
									<ContactSearch
										autoFocus
										name="contact"
										where={`{ _not: { MealProposalsContacts: { MealProposalId: { _eq: "${MealProposalId}" } } } }`}
									/>
								)}
							</DialogForm>
						))
					}
				>
					Add Existing Contact
				</PrimaryButton>
			</GridContainer>
		</LoadingStateContainer>
	);
};

MealProposalContacts.propTypes = {
	mealProposal: PropTypes.shape({
		ClientLocationId: PropTypes.string.isRequired,
		createdAt: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
	}),
};

export default MealProposalContacts;
