import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { put } from '@sporkbytes/api-client/Users';

import { useAuth } from 'services/auth';
import axios from 'services/data';

const ToggleActiveButton = ({ active, id }) => {
	const { userCan } = useAuth();
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Changing loading state when the update request finishes
	 * leads to the button being enabled briefly before the user disappears
	 * due to using a subscription at the page level.
	 */
	useEffect(() => {
		setIsLoading(false);
	}, [active]);

	return (
		userCan(['users:edit']) && (
			<Button
				size="small"
				color="primary"
				onClick={() => {
					setIsLoading(true);

					put(axios, id, {
						active: !active,
					});
				}}
				disabled={isLoading}
			>
				{active ? 'Deactivate' : 'Activate'}
			</Button>
		)
	);
};

ToggleActiveButton.propTypes = {
	active: PropTypes.bool.isRequired,
	id: PropTypes.string.isRequired,
};

export { ToggleActiveButton };
