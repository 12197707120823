import React, { useContext } from 'react';
import gql from 'graphql-tag';
import Chart from 'react-apexcharts';
import { totalReceivedByPartner } from '@sporkbytes/meal-proposal-utils';
import { startCase } from 'lodash-es';

import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

import { formatCurrency, formatGroupedDate } from 'services/mealProposal';

const PartnerPayoutsByTimePeriod = () => {
	const { chartConfig, filters, groupedMealProposalsForCalculations } =
		useContext(AnalyticsContext);

	const options = {
		...chartConfig.options,
		chart: {
			...chartConfig.options.chart,
		},
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: groupedMealProposalsForCalculations.map(
				({ date }) => date
			),
			labels: {
				formatter: value => formatGroupedDate(value, filters.groupBy),
			},
		},
		yaxis: [
			{
				seriesName: 'Payout',
				labels: {
					formatter: formatCurrency,
				},
			},
		],
	};

	const series = [
		{
			name: 'Payout',
			data: groupedMealProposalsForCalculations.map(({ MealProposals }) =>
				totalReceivedByPartner(MealProposals, filters.PartnerId)
			),
		},
	];

	return (
		<>
			<WidgetTitle>Payout by {startCase(filters.groupBy)}</WidgetTitle>
			<Chart
				type="bar"
				series={series}
				options={options}
				height={chartConfig.height}
			/>
		</>
	);
};

PartnerPayoutsByTimePeriod.fragments = {
	details: gql`
		fragment PartnerPayoutsByTimePeriodDetails on MealProposals {
			deliveryDate
			headCount
			MealOptions {
				approved
				PurchaseOrders {
					sporkCommissionPercent
					PartnerLocation {
						Partner {
							id
							name
						}
					}
					PurchaseOrderMenuSections {
						PurchaseOrderMenuItems {
							discount
							paidToPartner
							quantity
						}
					}
					PurchaseOrderPayoutAdjustments {
						amount
					}
				}
			}
		}
	`,
};

export default PartnerPayoutsByTimePeriod;
