import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import {
	approve,
	confirm,
	disapprove,
	undoConfirmation,
} from '@sporkbytes/api-client/MealOptions';
import { noop } from 'lodash-es';

import {
	edit,
	machineEventActionObjects,
	sendShareableMenuLink,
	viewMenu,
} from 'components/meal-options/MealOptionActionMenu/actions/';
import ActionMenu from 'components/utilities/ActionMenu';

import { createStateEventActions } from 'services/actionMenu';
import { useAuth } from 'services/auth';
import apolloClient from 'services/apollo';
import axios from 'services/data';

import useMealOptionMachine from 'hooks/useMealOptionMachine';
import useQueryString from 'hooks/useQueryString';

import mealOptionMachine from 'machines/mealOptionMachine';

const MealOptionActionMenu = ({ id }) => {
	const { userCan } = useAuth();
	const [queryString, setQueryString] = useQueryString();
	const history = useHistory();
	const [state, send] = useMealOptionMachine(id, {
		actions: {
			delete: noop, // the machine must define something for an event to trigger, so a noop function suffices since our usual delete action will get triggered anyway
			viewMenu: async () => {
				const { data } = await apolloClient.query({
					query: gql`
						query mealOptionMealMenuId($id: uuid!) {
							MealOptions_by_pk(id: $id) {
								mealMenuId
							}
						}
					`,
					variables: { id },
				});

				history.openNamedRoute('publicMealMenu', {
					mealMenuId: data.MealOptions_by_pk.mealMenuId,
				});
			},
		},
		services: {
			approve: () => approve(axios, id),
			confirm: () => confirm(axios, id),
			disapprove: () => disapprove(axios, id),
			undoConfirmation: () => undoConfirmation(axios, id),
		},
	});

	return (
		<ActionMenu
			options={[
				...createStateEventActions(
					state,
					mealOptionMachine,
					send,
					machineEventActionObjects({
						id,
						queryString,
						setQueryString,
						state,
						userCan,
					}),
					{
						hiddenEvents: ['UPDATE_DATA'],
					}
				),
				edit({ id, state, userCan }),
				sendShareableMenuLink({ id, state, userCan }),
				viewMenu({ history, id, state }),
			]}
		/>
	);
};

MealOptionActionMenu.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MealOptionActionMenu;
