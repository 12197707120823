import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { TablePagination } from '@material-ui/core';
import { SingleSelect } from '@sporkbytes/material-ui-kit-react';
import { pick, startCase } from 'lodash-es';

import MealProposalFilters, {
	transformQueryString,
} from 'components/meal-proposals/MealProposalFilters';
import MealProposalMetrics from 'components/meal-proposals/MealProposalMetrics';
import Page from 'components/layout/Page';
import Table from 'components/content/Table';

import useQueryString from 'hooks/useQueryString';

import { getColumns } from 'pages/home/helpers';
import { getMealProposalsQuery } from 'pages/home/queries';

import { getFilters } from 'services/mealProposal';

const DATE_OPTIONS = ['deliveryDate', 'createdAt', 'updatedAt'];

const Home = () => {
	const [queryString, setQueryString] = useQueryString();

	const transformedQueryString = transformQueryString(queryString, {
		defaultStatus: ['Draft', 'In Progress'],
	});

	const { data, loading } = useQuery(
		getMealProposalsQuery(transformedQueryString)
	);

	const changeQueryString = queryStringChanges =>
		setQueryString({ ...queryString, ...queryStringChanges });

	return (
		<Page title="Home">
			<MealProposalFilters
				value={pick(transformedQueryString, [
					'beginDate',
					'CancellationReasonId',
					'ClientId',
					'ClientLocationId',
					'createdBy',
					'endDate',
					'filteringBy',
					'MealCategoryIds',
					'PartnerId',
					'SporkContactId',
					'status',
					'unapprovedBy',
				])}
				onChange={filters =>
					changeQueryString({
						...filters,
						page: undefined,
					})
				}
			>
				{({ onChange }) => (
					<SingleSelect
						label="Filtering By"
						options={DATE_OPTIONS}
						value={transformedQueryString.filteringBy}
						onChange={filteringBy => onChange({ filteringBy })}
						getOptionLabel={startCase}
					/>
				)}
			</MealProposalFilters>

			<MealProposalMetrics filters={getFilters(transformedQueryString)} />

			<Table
				isLoading={loading}
				columns={getColumns(transformedQueryString)}
				data={data?.MealProposals}
				onSearchChange={searchTerm =>
					changeQueryString({
						searchTerm,
						page: undefined,
					})
				}
				onOrderChange={(orderColumn, orderDirection) => {
					const orderBy = getColumns(transformedQueryString)[
						orderColumn
					].field;

					changeQueryString({
						orderBy,
						orderDirection,
					});
				}}
				onChangeRowsPerPage={pageSize => {
					changeQueryString({
						pageSize,
						page: undefined,
					});
				}}
				components={{
					// This is needed in order to get pagination to work properly without using the remote data feature https://github.com/mbrn/material-table/issues/361#issuecomment-475786434
					Pagination: props => (
						<TablePagination
							{...props}
							count={
								data?.MealProposals_aggregate.aggregate.count ||
								0
							}
							onChangePage={(e, page) => {
								changeQueryString({
									page: page + 1, // page will be 1-indexed in the query string but 0-indexed in code
								});
							}}
							page={
								data === undefined
									? 0
									: transformedQueryString.page
							}
							rowsPerPage={transformedQueryString.pageSize}
						/>
					),
				}}
				options={{
					debounceInterval: 500,
					searchAutoFocus: true,
					initialPage: transformedQueryString.page,
					pageSize: transformedQueryString.pageSize,
					searchText: transformedQueryString.searchTerm,
				}}
			/>
		</Page>
	);
};

export default Home;
