import React from 'react';
import PropTypes from 'prop-types';
import { camelCase, kebabCase } from 'lodash-es';

import Link from 'components/routing/Link';

const DetailPageLink = ({ entityType, id, text, ...props }) => (
	<Link
		{...props}
		routeName={`${camelCase(entityType)}Detail`}
		variables={{ id }}
		permissions={[`${kebabCase(entityType)}:view`]}
	>
		{text}
	</Link>
);

DetailPageLink.propTypes = {
	entityType: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default DetailPageLink;
