import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { Switch } from '@sporkbytes/material-ui-kit-react';
import { kebabCase } from 'lodash-es';

import BooleanStatusIndicator from './BooleanStatusIndicator';

import { useAuth } from 'services/auth';
import axios from 'services/data';

const BooleanFieldSwitch = ({
	afterChange,
	columnToEdit,
	entityId,
	entityType,
	updateEntity,
	value,
}) => {
	const { userCan } = useAuth();
	const [isLoading, setIsLoading] = useState(false);

	if (isLoading) {
		return <CircularProgress size={30} />;
	}

	if (userCan([`${kebabCase(entityType)}:edit`])) {
		return (
			<Switch
				label=""
				onChange={async newValue => {
					setIsLoading(true);

					try {
						await updateEntity(axios, entityId, {
							[columnToEdit]: newValue,
						});

						await afterChange();
					} catch (error) {
						console.error(error);
					} finally {
						setIsLoading(false);
					}
				}}
				value={value}
			/>
		);
	}

	return <BooleanStatusIndicator value={value} type="filled" />;
};

BooleanFieldSwitch.propTypes = {
	afterChange: PropTypes.func,
	columnToEdit: PropTypes.string,
	entityId: PropTypes.string.isRequired,
	entityType: PropTypes.string.isRequired,
	updateEntity: PropTypes.func.isRequired,
	value: PropTypes.bool.isRequired,
};

BooleanFieldSwitch.defaultProps = {
	afterChange: () => {},
	columnToEdit: 'active',
};

export default BooleanFieldSwitch;
