import React, { useContext, useState } from 'react';
import gql from 'graphql-tag';
import Chart from 'react-apexcharts';
import { Grid } from '@material-ui/core';
import { format, isWeekend, parseISO } from 'date-fns';
import { SingleSelect } from '@sporkbytes/material-ui-kit-react';
import { roundNumberToDigits } from '@sporkbytes/math-utils';
import { maxBy, meanBy, startCase, uniqBy } from 'lodash-es';

import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

import { formatGroupedDate } from 'services/mealProposal';

const DeliveryDriverNeedsOverTime = () => {
	const [includedDays, setIncludedDays] = useState('weekdaysOnly');
	const { chartConfig, filters, groupedMealProposalsForCalculations } =
		useContext(AnalyticsContext);

	let stats = groupedMealProposalsForCalculations.reduce(
		(timeGroups, timeGroup) => {
			return [
				...timeGroups,
				{
					date: format(parseISO(timeGroup.date), 'yyyy-MM-dd'),
					drivers: timeGroup.MealProposals.reduce(
						(driversByDay, mealProposal) => {
							const dateOfMeal = format(
								parseISO(mealProposal.deliveryDate),
								'yyyy-MM-dd'
							);
							const driverIds =
								mealProposal.MealProposalsDeliveryDrivers.map(
									({ DeliveryDriverId }) => DeliveryDriverId
								);
							const dateOfMealIndex = driversByDay.findIndex(
								day => day.date === dateOfMeal
							);
							const dateOfMealIsWeekend = isWeekend(
								parseISO(mealProposal.deliveryDate)
							);

							if (
								dateOfMealIsWeekend &&
								includedDays === 'weekdaysOnly'
							) {
								return driversByDay;
							}

							if (dateOfMealIndex > -1) {
								driversByDay[dateOfMealIndex] = {
									date: driversByDay[dateOfMealIndex].date,
									drivers: uniqBy([
										...driverIds,
										...driversByDay[dateOfMealIndex]
											.drivers,
									]),
								};
							} else {
								driversByDay.push({
									date: dateOfMeal,
									drivers: driverIds,
								});
							}

							return driversByDay;
						},
						[]
					),
				},
			];
		},
		[]
	);

	stats = stats.map(({ date, drivers }) => {
		const maxDriverDay = maxBy(drivers, dates => dates.drivers.length);
		let maxDrivers;

		if (maxDriverDay) {
			maxDrivers = maxDriverDay.drivers.length;
		} else {
			maxDrivers = 0;
		}

		return {
			date,
			maxDrivers,
			avgDrivers: roundNumberToDigits(
				meanBy(drivers, dates => dates.drivers.length) || 0,
				1
			),
		};
	});

	const options = {
		...chartConfig.options,
		xaxis: {
			categories: stats.map(({ date }) => date),
			labels: {
				formatter: value => formatGroupedDate(value, filters.groupBy),
			},
		},
	};

	const series = [
		{
			name: 'Max Drivers Per Day',
			data: stats.map(({ maxDrivers }) => maxDrivers),
		},
		{
			name: 'Average Drivers Per Day',
			data: stats.map(({ avgDrivers }) => avgDrivers),
		},
	];

	return (
		<>
			<Grid
				container
				direction="row"
				justify="space-between"
				alignItems="center"
				spacing={2}
			>
				<Grid item>
					<WidgetTitle>Driver Needs Over Time</WidgetTitle>
				</Grid>
				<Grid item xs={12} sm={3} md={2}>
					<SingleSelect
						getOptionLabel={startCase}
						label="Included Days"
						onChange={setIncludedDays}
						options={['allDays', 'weekdaysOnly']}
						value={includedDays}
					/>
				</Grid>
			</Grid>
			<Chart
				type="line"
				series={series}
				options={options}
				height={chartConfig.height}
			/>
		</>
	);
};

DeliveryDriverNeedsOverTime.fragments = {
	details: gql`
		fragment DeliveryDriverNeedsOverTimeDetails on MealProposals {
			deliveryDate
			MealProposalsDeliveryDrivers {
				DeliveryDriverId
			}
		}
	`,
};

export default DeliveryDriverNeedsOverTime;
