import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { startCase } from 'lodash-es';

import DateOutput from 'components/content/DateOutput';
import RemoteTable from 'components/content/RemoteTable';
import { getMultiWordSearchWhereClause } from 'services/search';

const getEmailActivityLogsQuery = searchTerm => gql`
	query EmailActivityLogs(
		$MealProposalActivityLogId: uuid!
		$eventOrderBy: order_by
		$timestampOrderBy: order_by
		$contactOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		EmailActivityLogs(
			where: {
				_or: [
					{ event: { _ilike: "%${searchTerm}%" } }
					{ Contact: { _and: [${getMultiWordSearchWhereClause(searchTerm)}] } }
				]
				MealProposalActivityLogId: { _eq: $MealProposalActivityLogId }
			}
			order_by: {
				event: $eventOrderBy
				timestamp: $timestampOrderBy
				Contact: { fullName: $contactOrderBy }
			}
			limit: $limit
			offset: $offset
		) {
			event
			timestamp
			Contact {
				fullName
			}
		}
		EmailActivityLogs_aggregate(
			where: {
				_or: [
					{ event: { _ilike: "%${searchTerm}%" } }
					{ Contact: { _and: [${getMultiWordSearchWhereClause(searchTerm)}] } }
				]
				MealProposalActivityLogId: { _eq: $MealProposalActivityLogId }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const EmailActivityLogsTable = ({ MealProposalActivityLogId }) => {
	return (
		<RemoteTable
			columns={[
				{
					title: 'Event',
					field: 'event',
					render: ({ event }) => startCase(event),
				},
				{
					title: 'Contact',
					field: 'contact',
					render: ({ Contact }) =>
						Contact?.fullName ?? 'No Contact Found',
				},
				{
					title: 'Date',
					field: 'timestamp',
					render: ({ timestamp }) => <DateOutput date={timestamp} />,
				},
			]}
			defaultOrderBy="timestamp"
			defaultOrderDirection="desc"
			getQuery={getEmailActivityLogsQuery}
			queryEntity="EmailActivityLogs"
			variables={{ MealProposalActivityLogId }}
		/>
	);
};

EmailActivityLogsTable.propTypes = {
	MealProposalActivityLogId: PropTypes.string.isRequired,
};

export default EmailActivityLogsTable;
