import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
	AttachMoney,
	DirectionsCar,
	People,
	RoomService,
	Warning,
} from '@material-ui/icons';
import { confirmationWindowLengthInHours } from '@sporkbytes/config';
import { Grid } from '@material-ui/core';
import {
	Form,
	GridContainer,
	LinkedCheckboxGroup,
	LinkedCurrencyInput,
	LinkedDateTimePicker,
	LinkedIntegerInput,
	LinkedRadioGroup,
	LinkedRichTextEditor,
	LinkedSwitch,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';
import { getDiff } from '@sporkbytes/object-utils';
import { pick } from 'lodash-es';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import ChecklistDialogForm from 'components/forms/ChecklistDialogForm';
import ClientLocationSearch from 'components/client-locations/ClientLocationSearch';
import FormSectionHeader from 'components/forms/FormSectionHeader';
import FormSectionSubheader from 'components/forms/FormSectionSubheader';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import SporkLocationLinkedSingleSelect from 'components/spork-locations/SporkLocationLinkedSingleSelect';

import * as yup from 'models/validation';
import { useStore } from 'models/store';

import { DEFAULT_TIMEZONE } from 'services/dates';
import apolloClient from 'services/apollo';

const CLIENT_LOCATION_DETAIL = gql`
	query ClientLocationDetailForMealProposalForm($id: uuid!) {
		ClientLocations_by_pk(id: $id) {
			automatedApprovalThankYou
			automatedEmailConfirmation
			automatedReviewRequest
			budgetPerPerson
			deliveryInstructions
			dietaryRestrictions
			fullAddress
			headCount
			setupInstructions
			timezone
			totalBudget
			Client {
				id
				logoUrl
				name
			}
		}
	}
`;

const MEAL_PROPOSAL_FORM_QUERY = gql`
	query MealProposalForm {
		MealCategories(
			where: { active: { _eq: true } }
			order_by: { sortOrder: asc }
		) {
			id
			name
		}
	}
`;

const businessUnitOptions = ['Spork Bytes', 'Spork Box'];

const validationSchema = yup.object().shape({
	automatedApprovalThankYou: yup.boolean(),
	automatedEmailConfirmation: yup.boolean(),
	automatedReviewRequest: yup.boolean(),
	budgetPerPerson: yup.positiveNumber(),
	businessUnit: yup
		.mixed()
		.oneOf(businessUnitOptions)
		.required('Please select a business unit.'),
	ClientLocationId: yup.string().required('Please select a client location.'),
	deliveryDate: yup
		.date()
		.required('Please choose a date and ready to eat time.'),
	deliveryInstructions: yup.string(),
	dietaryRestrictions: yup.string(),
	headCount: yup.positiveInteger().required('Please enter the head count.'),
	MealCategories: yup
		.array()
		.of(yup.string())
		.required('Please select at least one Meal Category.'),
	name: yup.name(),
	setupInstructions: yup.string(),
	SporkLocationId: yup.sporkLocationId(),
	totalBudget: yup.positiveNumber(),
});

const onClientLocationChange =
	(
		{ setSelectedClientLocation, showDialog, showErrorNotification },
		{ setFieldValue, setSubmitting, values }
	) =>
	async option => {
		if (!!option) {
			setFieldValue('ClientLocationId', option.id);

			setSubmitting(true); // disables the submit button while we get the needed details for the selected ClientLocation

			try {
				const {
					data: { ClientLocations_by_pk },
				} = await apolloClient.query({
					query: CLIENT_LOCATION_DETAIL,
					variables: {
						id: option.id,
					},
				});

				// Find the values that are different between the newly selected Client Location and what is currently set in the Meal Proposal
				const fieldsToCompare = [
					'automatedApprovalThankYou',
					'automatedEmailConfirmation',
					'automatedReviewRequest',
					'budgetPerPerson',
					'deliveryInstructions',
					'dietaryRestrictions',
					'headCount',
					'setupInstructions',
					'totalBudget',
				];
				const mealProposalValues = pick(values, fieldsToCompare);
				const clientLocationValues = Object.fromEntries(
					Object.entries(
						pick(ClientLocations_by_pk, fieldsToCompare)
					).map(([key, value]) => [
						key,
						value === null ? undefined : value, // nulls need to be converted to undefined to match form values
					])
				);

				const clientLocationChangedFields = getDiff(
					mealProposalValues,
					clientLocationValues
				);

				if (Object.keys(clientLocationChangedFields).length > 0)
					showDialog(ChecklistDialogForm, {
						title: 'Apply Defaults',
						text: 'Choose which values to change based on the selected Client Location.',
						submitButtonContent: 'Apply',
						cancelButtonText: 'Ignore All',
						initialValues: Object.keys(clientLocationChangedFields),
						options: Object.keys(clientLocationChangedFields).map(
							field => ({ id: field })
						),
						onSubmit: checkedFieldsIds => {
							checkedFieldsIds.forEach(id => {
								setFieldValue(
									id,
									clientLocationChangedFields[id]
								);
							});

							return Promise.resolve();
						},
					});
				setSelectedClientLocation(
					pick(ClientLocations_by_pk, [
						'fullAddress',
						'timezone',
						'Client',
					])
				);
			} catch (error) {
				showErrorNotification(
					'There was an error setting default values.  Please double-check all values.'
				);
			} finally {
				setSubmitting(false);
			}
		} else {
			setFieldValue('ClientLocationId', undefined);
			setSelectedClientLocation({
				timezone: DEFAULT_TIMEZONE,
			});
		}
	};

const MealProposalForm = ({ clientLocation, pageSubheaderText, ...props }) => {
	const { loading, data } = useQuery(MEAL_PROPOSAL_FORM_QUERY);
	const [selectedClientLocation, setSelectedClientLocation] = useState({
		timezone: DEFAULT_TIMEZONE,
		...clientLocation,
	});
	const { hasMoreThanOneSporkLocation, showDialog, showErrorNotification } =
		useStore();

	if (loading) {
		return <CenteredCircularProgress />;
	}

	return (
		<>
			<PageHeader
				imgUrl={selectedClientLocation?.Client?.logoUrl}
				headerText={
					selectedClientLocation?.Client?.name ||
					'Select a Client Location'
				}
			/>
			<PageSubheader>{pageSubheaderText}</PageSubheader>
			<Form {...props} validationSchema={validationSchema}>
				{formikBag => (
					<>
						<FormSectionHeader>Location and Time</FormSectionHeader>
						<GridContainer
							columns={hasMoreThanOneSporkLocation() ? 2 : 3}
						>
							{hasMoreThanOneSporkLocation() ? (
								<SporkLocationLinkedSingleSelect />
							) : null}
							<ClientLocationSearch
								autoFocus
								name="ClientLocationId"
								placeholder="Search for a client location"
								onChange={onClientLocationChange(
									{
										setSelectedClientLocation,
										showDialog,
										showErrorNotification,
									},
									formikBag
								)}
								where={`ClientLocation: { active: { _eq: true } }`}
							/>
							<LinkedDateTimePicker
								name="deliveryDate"
								label="Delivery Date / Ready to Eat Time"
								timezone={selectedClientLocation.timezone}
								disablePast={true}
								minutesStep={5}
							/>
							<LinkedTextInput name="name" />
						</GridContainer>
						<FormSectionSubheader>Address</FormSectionSubheader>
						{!!selectedClientLocation.fullAddress ? (
							<p>{selectedClientLocation.fullAddress}</p>
						) : (
							<p>Please select a client location.</p>
						)}
						<FormSectionHeader>Analysis</FormSectionHeader>
						<Grid container>
							<Grid item xs={12} sm={6} md={4}>
								<LinkedRadioGroup
									name="businessUnit"
									options={businessUnitOptions}
									row
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={8}>
								<LinkedCheckboxGroup
									name="MealCategories"
									label="Meal Categories"
									options={data.MealCategories}
									getOptionLabel={option => option.name}
									getOptionValue={option => option.id}
									getOptionKey={option => option.id}
									row
								/>
							</Grid>
						</Grid>
						<FormSectionHeader>Details</FormSectionHeader>
						<GridContainer>
							<LinkedIntegerInput
								name="headCount"
								icon={<People />}
							/>
							<LinkedCurrencyInput
								name="budgetPerPerson"
								icon={<AttachMoney />}
							/>
							<LinkedCurrencyInput
								name="totalBudget"
								icon={<AttachMoney />}
							/>
						</GridContainer>
						<GridContainer>
							<LinkedRichTextEditor
								name="deliveryInstructions"
								icon={<DirectionsCar />}
							/>
						</GridContainer>
						<GridContainer>
							<LinkedRichTextEditor
								name="setupInstructions"
								icon={<RoomService />}
							/>
						</GridContainer>
						<GridContainer>
							<LinkedRichTextEditor
								name="dietaryRestrictions"
								icon={<Warning />}
							/>
						</GridContainer>
						<FormSectionHeader>Automated Emails</FormSectionHeader>
						<GridContainer columns={3}>
							<LinkedSwitch
								name="automatedApprovalThankYou"
								uncheckedHelperText="No thank you email will be sent to the client when they approve the meal."
								checkedHelperText="A thank you email will be sent automatically to the assigned client contacts when one of them approves the meal. No email will be sent if Spork approves on their behalf."
							/>
							<LinkedSwitch
								name="automatedEmailConfirmation"
								uncheckedHelperText="The meal's confirmation email for the assigned client contacts must be sent manually."
								checkedHelperText={`A confirmation email will be sent automatically ${confirmationWindowLengthInHours.client} hours before delivery to the assigned client contacts.`}
							/>
							<LinkedSwitch
								name="automatedReviewRequest"
								uncheckedHelperText="No review request email will be sent."
								checkedHelperText="A review request email will be sent automatically the day after the meal to the assigned client contacts."
							/>
						</GridContainer>
					</>
				)}
			</Form>
		</>
	);
};

MealProposalForm.propTypes = {
	clientLocation: PropTypes.shape({
		fullAddress: PropTypes.string,
		timezone: PropTypes.string,
		Client: PropTypes.shape({
			id: PropTypes.string,
			logoUrl: PropTypes.string,
			name: PropTypes.string,
		}),
	}),
	pageSubheaderText: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element,
	]).isRequired,
};

MealProposalForm.defaultProps = {
	clientLocation: {},
};

export default MealProposalForm;
