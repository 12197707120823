import React from 'react';
import { Cancel, Check, DoneAll } from '@material-ui/icons';
import { deleteMealOptions } from '@sporkbytes/api-client/MealOptions';
import { omit } from 'lodash-es';

import { createDeleteAction } from 'services/actionMenu';

export default ({ id, queryString, setQueryString, state, userCan }) => ({
	APPROVE: {
		canPerformAction: userCan(['meal-options:approve']),
		icon: <Check />,
		label: `Approve ${state.context?.name}`,
		type: 'success',
	},
	CONFIRM: {
		canPerformAction: userCan(['meal-options:confirm']),
		label: `Confirm ${state.context?.name}`,
		icon: <DoneAll />,
		type: 'success',
	},
	DELETE: createDeleteAction({
		afterDelete: async () => {
			// we don't want to try to display a Meal Option or Purchase Order that no longer exists
			setQueryString(
				omit(queryString, ['meal-option', 'purchase-order'])
			);
		},
		canPerformAction: userCan(['meal-options:delete']),
		deleteFunction: deleteMealOptions,
		entityId: id,
		entityName: state.context?.name,
	}),
	DISAPPROVE: {
		canPerformAction: userCan(['meal-options:disapprove']),
		label: `Disapprove ${state.context?.name}`,
		icon: <Cancel />,
		type: 'error',
	},
	UNDO_CONFIRMATION: {
		canPerformAction: userCan(['meal-options:undo-confirmation']),
		label: `Undo Confirmation for ${state.context?.name}`,
		icon: <Cancel />,
		type: 'success',
	},
});
