import React from 'react';
import {
	Form,
	GridContainer,
	LinkedTextInput,
	LinkedCurrencyInput,
	LinkedPercentInput,
	LinkedSingleSelect,
} from '@sporkbytes/material-ui-kit-react';

import * as yup from 'models/validation';
import { startCase } from 'lodash-es';

const TYPES = ['flat', 'percent', 'per person'];

const MealProposalFeeForm = props => {
	const validationSchema = yup.object().shape({
		name: yup.name().required(),
		description: yup.string().max(100),
		amount: yup.nonNegativeNumber().required('Please enter an amount.'),
		type: yup.mixed().oneOf(TYPES).required('Please select a type.'),
		discount: yup.positiveNumber().nullable(),
	});

	return (
		<Form {...props} validationSchema={validationSchema}>
			{({ values }) => (
				<>
					<GridContainer columns={2}>
						<LinkedTextInput name="name" autoFocus />
						<LinkedTextInput
							name="description"
							label="Description"
						/>
					</GridContainer>
					<GridContainer>
						{values.type === 'percent' ? (
							<LinkedPercentInput name="amount" />
						) : (
							<LinkedCurrencyInput name="amount" />
						)}
						<LinkedSingleSelect
							name="type"
							options={TYPES.map(type => ({
								value: type,
								label: startCase(type),
							}))}
							getOptionValue={option => option.value}
							getOptionLabel={option => option.label}
							getOptionKey={option => option.value}
						/>
						<LinkedPercentInput name="discount" />
					</GridContainer>
				</>
			)}
		</Form>
	);
};

export default MealProposalFeeForm;
